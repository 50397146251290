import Accordion from "react-bootstrap/Accordion";
import ContentImage from "components/Images/ContentImage";
import DocButtonLink from "components/Links/DocButtonLink";
import GalleryGroup from "components/ContentGroup/GalleryGroup";
import MapInset from "components/InteractiveMap/MapInset";
import { FrequentlyAskedQuestion } from "components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import * as React from 'react';
export default {
  Accordion,
  ContentImage,
  DocButtonLink,
  GalleryGroup,
  MapInset,
  FrequentlyAskedQuestion,
  React
};