import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import { ChatFill, CaretRightSquareFill, CaretLeftSquareFill, InfoCircleFill } from "react-bootstrap-icons";
import Splash_screen_slide_01 from "src/images/modals/Splash_screen_slide_01.png";
import Splash_screen_slide_02 from "src/images/modals/Splash_screen_slide_02.png";
import Splash_screen_slide_03 from "src/images/modals/Splash_screen_slide_03.png";
import * as React from 'react';
export default {
  Carousel,
  Modal,
  ChatFill,
  CaretRightSquareFill,
  CaretLeftSquareFill,
  InfoCircleFill,
  Splash_screen_slide_01,
  Splash_screen_slide_02,
  Splash_screen_slide_03,
  React
};