import React, { useState, useEffect } from "react";

import axios from "axios";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import EventCard from "components/EventCard/EventCard";

const upcomingEventsDisplayText = (text) => {
  return (
    <Card className="text-center">
      <Card.Body>
        <Card.Title className="text-center mb-0">{text}</Card.Title>
      </Card.Body>
    </Card>
  );
};

const loadingEventsContent = (
  <Card className="text-center">
    <Card.Body>
      <Spinner animation="border" />
    </Card.Body>
  </Card>
);

const errorFetchingEventsContent = upcomingEventsDisplayText(
  "There were issues fetching upcoming events"
);

const noEventsContent = upcomingEventsDisplayText(
  "There are currently no upcoming events"
);

const buildCardEventsContent = (upcomingEvents) =>
  upcomingEvents.map((upcomingEvent, index) => {
    const eventCardProps = {
      eventTitle: { text: upcomingEvent.title },
      eventLocation: {
        text: upcomingEvent.location,
      },
      eventDateTimeNaive: {
        startDate: upcomingEvent.start_date,
        startTime: upcomingEvent.start_time,
        endDate: upcomingEvent.end_date,
        endTime: upcomingEvent.end_time,
      },
    };

    return <EventCard key={index} {...eventCardProps} />;
  });

const EventCardGroup = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let fetchHeaders = {};
    if (process.env.GATSBY_WSIA_BACKEND_API_TOKEN) {
      fetchHeaders = {
        Authorization: `Token ${process.env.GATSBY_WSIA_BACKEND_API_TOKEN}`,
      };
    }
    const backendUrl = `${process.env.GATSBY_WSIA_BACKEND_BASE_URL}/api/upcoming_events/events/`;

    axios
      .get(backendUrl, { headers: fetchHeaders })
      .then((response) => {
        setData(response.data);
        setLoading(false);
        setError(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((_) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  let cardGroupContent = noEventsContent;
  if (loading) {
    cardGroupContent = loadingEventsContent;
  } else if (error) {
    cardGroupContent = errorFetchingEventsContent;
  } else if (data.length) {
    cardGroupContent = buildCardEventsContent(data);
  }

  return <div className="card-group">{cardGroupContent}</div>;
};

export default EventCardGroup;
