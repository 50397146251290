import _get from "lodash/get";

import { mapConfig } from "src/app-config";

import {
  SET_LAYER_VISIBILITY,
  SET_TOPIC_LAYERS,
} from "../constants/ActionTypes";

const visibleLayerIds = _get(
  mapConfig,
  "mapSettings.initialMapState.layerOptions.visible",
  []
);

const layers = mapConfig.layers.reduce((accumulator, layer) => {
  accumulator[layer.id] = { visible: visibleLayerIds.includes(layer.id) };
  return accumulator;
}, {});

const initialState = {
  layers: layers,
  topicLayerIds: [],
};

const interactiveMap = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYER_VISIBILITY:
      return {
        ...state,
        layers: {
          ...state.layers,
          [action.payload.layerId]: {
            ...state.layers[action.payload.layerId],
            visible: action.payload.visible,
          },
        },
      };
    case SET_TOPIC_LAYERS:
      return {
        ...state,
        topicLayerIds: action.layerIds,
      };
    default:
      return state;
  }
};

export default interactiveMap;
