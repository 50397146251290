import React from "react";

import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";

import Thumbnail from "components/Images/Thumbnail";
import { baseThumbnailProps, lightboxSources } from "components/Images/types";

const Gallery = ({ thumbnailProps, lightboxSources }) => {
  const thumbnails = thumbnailProps.map((config, index) => {
    return (
      <Thumbnail key={index} lightboxSources={lightboxSources} {...config} />
    );
  });

  return <Row>{thumbnails}</Row>;
};

Gallery.propTypes = {
  thumbnailProps: PropTypes.arrayOf(PropTypes.shape(baseThumbnailProps))
    .isRequired,
  lightboxSources: lightboxSources.isRequired,
};

export default Gallery;
