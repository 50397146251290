import React from "react";

import { Form } from "react-bootstrap";

const DEFAULT_CHOICE = "---";
const THEME_CHOICES = [
  "Primary",
  "Secondary",
  "Success",
  "Danger",
  "Warning",
  "Info",
  "Light",
  "Dark",
];

const ThemeColourChoiceField = ({
  controlId,
  className,
  label,
  changeFunction,
  value,
}) => {
  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        value={value}
        onChange={(event) => changeFunction(event.target.value.toLowerCase())}
        custom
      >
        <option>{DEFAULT_CHOICE}</option>
        {THEME_CHOICES.map((item, index) => {
          return (
            <option key={index} value={item.toLowerCase()}>
              {item}
            </option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
};

export default ThemeColourChoiceField;
