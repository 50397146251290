/**
 * Props validation functions to be used with PropTypes
 */

import PropTypes from "prop-types";

const checkProp = (prop, allowTypes, errorMessage) => {
  const isUndefined = prop === undefined;
  const typeAllowed = allowTypes.some((type) => {
    if (type === "array") {
      return Array.isArray(prop);
    }
    return typeof prop === type;
  });
  if (isUndefined || !typeAllowed) {
    return new Error(errorMessage);
  }
};

const checkMapboxSourceDataProp = (props, propName) => {
  // Mapbox allows GeoJSON to be specified as an object or external
  // GeoJSON document via its URL
  if (props["type"] === "geojson") {
    const allowTypes = ["string", "object"];
    const errorMessage = "Source is geojson please provide data property";
    return checkProp(props[propName], allowTypes, errorMessage);
  }
};

const checkMapboxSourceUrlProp = (props, propName) => {
  if (props["type"] === "image" || props["type"] === "video") {
    const allowTypes = ["string"];
    const errorMessage = `Source is ${props["type"]} please provide url`;
    return checkProp(props[propName], allowTypes, errorMessage);
  }
};

const checkMapboxSourceCoordinatesProp = (props, propName) => {
  if (props["type"] === "image" || props["type"] === "video") {
    const allowTypes = ["array"];
    const errorMessage = `Source is ${props["type"]} please provide coordinates array`;
    return checkProp(props[propName], allowTypes, errorMessage);
  }
};

const layerSource = PropTypes.shape({
  type: PropTypes.oneOf([
    "vector",
    "raster",
    "raster-dem",
    "geojson",
    "image",
    "video",
  ]).isRequired,
  data: checkMapboxSourceDataProp,
  url: checkMapboxSourceUrlProp,
  coordinates: checkMapboxSourceCoordinatesProp,
}).isRequired;

const checkMapboxLayerSourceLayerProp = (props, propName) => {
  const sourceType = props["source"]["type"];
  const sourceLayerIsDefined = props[propName] !== undefined;
  if (sourceType !== "vector" && sourceLayerIsDefined) {
    return new Error(
      "source-layer property prohibited where source.type is not vector"
    );
  }
  if (sourceType === "vector") {
    const allowTypes = ["string"];
    const errorMessage = `Source is ${sourceType} please provide source-layer`;
    return checkProp(props[propName], allowTypes, errorMessage);
  }
};

const styleTypes = PropTypes.oneOf([
  "fill",
  "line",
  "symbol",
  "circle",
  "heatmap",
  "fill-extrusion",
  "raster",
  "hillshade",
  "background",
]).isRequired;

export const layerDefinition = PropTypes.shape({
  id: PropTypes.string.isRequired,
  beforeId: PropTypes.string,
  type: styleTypes,
  "source-layer": checkMapboxLayerSourceLayerProp,
  source: layerSource,
}).isRequired;

export const styledVectorTile = PropTypes.shape({
  id: PropTypes.string.isRequired,
  beforeId: PropTypes.string,
  type: styleTypes,
  mapboxStyle: PropTypes.string.isRequired,
  "source-layer": checkMapboxLayerSourceLayerProp,
  source: layerSource,
});

export const featureProps = PropTypes.arrayOf(
  PropTypes.shape({
    featureId: PropTypes.string.isRequired,
    moduleComponentName: PropTypes.oneOf(["Thumbnail"]).isRequired,
    moduleProps: PropTypes.object.isRequired,
  })
).isRequired;

export const popups = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    layerId: PropTypes.string.isRequired,
    mouseEvent: PropTypes.oneOf(["hover", "click"]).isRequired,
    featureProps: featureProps,
  })
).isRequired;

const icon = PropTypes.shape({
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
});

export const icons = PropTypes.arrayOf(icon).isRequired;
