import { SET_ACTIVE_PAGE_SECTION } from "../constants/ActionTypes";

const initialState = {
  activeSectionId: "",
};

const pageSections = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE_SECTION:
      return {
        ...state,
        activeSectionId: action.activeSectionId,
      };
    default:
      return state;
  }
};

export default pageSections;
