/**
 * A higher order component to inject the document query and helpful methods
 * into the wrapped component.
 */

import React from "react";

import { StaticQuery, graphql } from "gatsby";

const withDocumentData = (WrappedComponent) => {
  return function WrappedWithDocumentData(props) {
    // The document query doesn't exist at this point, it must be passed in.
    const getDocumentURLByName = (documents, documentName) => {
      const documentEdge = documents.allFile.edges.find(
        ({ node }) => node.name === documentName
      );
      if (documentEdge !== undefined) {
        return documentEdge.node.publicURL;
      }
      return undefined;
    };

    return (
      <StaticQuery
        query={graphql`
          query GetAllDocuments {
            allFile(filter: { sourceInstanceName: { eq: "docs" } }) {
              edges {
                node {
                  name
                  publicURL
                }
              }
            }
          }
        `}
        render={(data) => (
          <WrappedComponent
            {...props}
            documents={data}
            getDocumentURLByName={getDocumentURLByName}
          />
        )}
      />
    );
  };
};

export default withDocumentData;
