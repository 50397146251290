/**
 * This component provides a mechanism for triggering a Virtual Consultation Room
 * hotspot rendered with MDX.
 *
 * This component should be imported and used in the hotspot MDX templates. It
 * supports pop-up style content, opening sources in a lightbox, or a link to
 * navigate or trigger a modal.
 *
 * If the content type is `hotspot` then this component expects 2 children, one
 * for the icon and one for the content. When the content type is `lightbox` or
 * `link`, only one child element is expected for the icon.
 */
import React, { useState } from "react";

import withLightboxTrigger from "components/Images/LightboxTrigger";
import Link from "components/Links/Link";

import MdxHotspotIcon from "./MdxHotspotIcon";
import withSceneChangeTrigger from "./SceneChangeTrigger";
import { HOTSPOT, LIGHTBOX, LINK, MdxHotspotPropTypes, SCENE } from "./types";

const triggerHOCWrappers = {
  [LIGHTBOX]: withLightboxTrigger,
  [SCENE]: withSceneChangeTrigger,
};

/**
 * Get a component wrapped with a HOC that will trigger a lightbox or scene change
 * when clicked.
 *
 * @param triggerType string, one of "lightbox" or "scene".
 * @returns {function(*): *}
 */
const getTriggerWrapperComponent = (triggerType) => {
  const functionName =
    triggerType === LIGHTBOX ? "triggerLightbox" : "triggerSceneChange";

  return triggerHOCWrappers[triggerType]((props) => {
    return (
      <div
        onClick={props[functionName]}
        onKeyDown={(event) =>
          event.key === "Enter" ? props[functionName] : undefined
        }
        role="button"
        tabIndex={0}
      >
        {props.children}
      </div>
    );
  });
};

const MdxHotspot = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasContent = props.contentType === HOTSPOT;

  // Change the hotspot state and call the callback function to let the hotspot
  // container react to the state change.
  const toggleHotspotContent = (value) => {
    setIsOpen(value);
    props.onTrigger(value);
  };

  let iconContent;
  if (props.contentType === LIGHTBOX) {
    const Wrapper = getTriggerWrapperComponent(LIGHTBOX);

    iconContent = (
      <Wrapper {...props.lightboxProps}>
        <MdxHotspotIcon tooltipText={props.tooltipText}>
          {props.children}
        </MdxHotspotIcon>
      </Wrapper>
    );
  } else if (props.contentType === LINK) {
    iconContent = (
      <Link {...props.linkProps}>
        <MdxHotspotIcon tooltipText={props.tooltipText}>
          {props.children}
        </MdxHotspotIcon>
      </Link>
    );
  } else if (props.contentType === SCENE) {
    const Wrapper = getTriggerWrapperComponent(SCENE);

    iconContent = (
      <Wrapper targetSceneId={props.targetSceneId}>
        <MdxHotspotIcon tooltipText={props.tooltipText}>
          {props.children}
        </MdxHotspotIcon>
      </Wrapper>
    );
  } else {
    iconContent = (
      <MdxHotspotIcon
        tooltipText={props.tooltipText}
        triggerOnClick={props.triggerOnClick}
        onClick={() => toggleHotspotContent(!isOpen)}
      >
        {props.children[0]}
      </MdxHotspotIcon>
    );
  }

  return (
    <div
      onMouseEnter={
        !props.triggerOnClick && hasContent
          ? () => toggleHotspotContent(true)
          : undefined
      }
      onMouseLeave={
        !props.triggerOnClick && hasContent
          ? () => toggleHotspotContent(false)
          : undefined
      }
    >
      {iconContent}
      {hasContent && isOpen && <div className="mt-1">{props.children[1]}</div>}
    </div>
  );
};

MdxHotspot.propTypes = MdxHotspotPropTypes;

MdxHotspot.defaultProps = {
  contentType: HOTSPOT,
  triggerOnClick: true,
};

export default MdxHotspot;
