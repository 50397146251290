import PropTypes from "prop-types";

const topicLayerOptions = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  }).isRequired
);

const controlGroup = PropTypes.shape({
  ids: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  label: PropTypes.string.isRequired,
}).isRequired;

const checkTitleProp = (props, propName) => {
  if (props["type"] === "group") {
    if (props[propName] === undefined) {
      return new Error("'type = group' requires a title");
    }
  }
};

const checkLayerProp = (props, propName, componentName) => {
  if (props["type"] === "single") {
    PropTypes.checkPropTypes(
      { [propName]: controlGroup },
      props,
      propName,
      componentName
    );
  }
};

const checkLayersProp = (props, propName, componentName) => {
  if (props["type"] === "group") {
    PropTypes.checkPropTypes(
      { [propName]: PropTypes.arrayOf(controlGroup).isRequired },
      props,
      propName,
      componentName
    );
  }
};

export const topicLayerControl = PropTypes.shape({
  type: PropTypes.oneOf(["group", "single"]).isRequired,
  title: checkTitleProp,
  layer: checkLayerProp,
  layers: checkLayersProp,
}).isRequired;

export const topicTitle = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  locationOptions: PropTypes.object.isRequired,
  layerOptions: topicLayerOptions,
};

const topicContent = {
  ...topicTitle,
  content: PropTypes.shape({
    elementId: PropTypes.string.isRequired,
    layerControls: PropTypes.arrayOf(topicLayerControl).isRequired,
  }).isRequired,
};

export const topics = PropTypes.arrayOf(PropTypes.shape(topicContent))
  .isRequired;

export const initialMapState = PropTypes.shape({
  locationOptions: PropTypes.object.isRequired,
  resetInitialLocation: PropTypes.bool.isRequired,
  layerOptions: PropTypes.shape({
    visible: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
});

const basemapConfig = PropTypes.shape({
  imageName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["style", "layer"]).isRequired,
  id: PropTypes.string.isRequired,
}).isRequired;

export const basemaps = PropTypes.shape({
  primary: basemapConfig,
  alternate: basemapConfig,
}).isRequired;
