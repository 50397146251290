import React from "react";

import { Col, Form } from "react-bootstrap";

const ImageFileNameField = ({ helpText, onFieldChange }) => {
  return (
    <Form.Row>
      <Form.Group as={Col} controlId="fileName">
        <Form.Label>Image filename</Form.Label>
        <Form.Control
          type="text"
          placeholder="my-image"
          required
          onChange={(event) => onFieldChange("fileName", event.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
        <Form.Text className="text-muted">{helpText}</Form.Text>
      </Form.Group>

      <Form.Group as={Col} controlId="imageHeight">
        <Form.Label>Image height</Form.Label>
        <Form.Control
          type="number"
          placeholder={125}
          required
          onChange={(event) => onFieldChange("imageHeight", event.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} controlId="imageWidth">
        <Form.Label>Image width</Form.Label>
        <Form.Control
          type="number"
          placeholder={125}
          required
          onChange={(event) => onFieldChange("imageWidth", event.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
};

export default ImageFileNameField;
