import React, { useState, useEffect } from "react";

import axios from "axios";
import PropTypes from "prop-types";
import { ChevronBarContract, ChevronDown } from "react-bootstrap-icons";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import ReactPaginate from "react-paginate";

const REACT_PAGINATION_STYLES = {
  pageClassName: "page-item",
  pageLinkClassName: "page-link",
  previousClassName: "d-none",
  previousLinkClassName: "d-none",
  nextClassName: "d-none",
  nextLinkClassName: "d-none",
  breakClassName: "page-item",
  breakLinkClassName: "page-link",
  containerClassName: "pagination justify-content-center my-4 my-sm-1",
  activeClassName: "active",
};

const getFileMetadataContent = (
  isConfidential,
  submissionFileType,
  submissionFileSize
) => {
  var metadataString = null;

  if (isConfidential) {
    metadataString = "-";
  } else if (submissionFileType != null || submissionFileSize != null) {
    metadataString = `(${[submissionFileType, submissionFileSize]
      .filter((x) => x !== null)
      .join(", ")})`;
  }

  if (metadataString != null) {
    return (
      <div className="col-lg-3 text-lg-right col-10 text-left text-muted">
        {metadataString}
      </div>
    );
  }

  return null;
};

const getTitleContent = (
  isConfidential,
  submissionTitle = "No title",
  submissionUrl
) => {
  if (isConfidential) {
    return (
      <div className="col-lg-8 col-12">
        <div className="text-muted">Confidential - {submissionTitle}</div>
      </div>
    );
  }

  if (submissionUrl != null) {
    return (
      <div className="col-lg-8 col-12">
        <a target="_blank" rel="noopener noreferrer" href={submissionUrl}>
          {submissionTitle}
        </a>
      </div>
    );
  }

  // We still want the title to be underlined when we hover over it.
  // Using a button keeps it accessible.
  return (
    <div className="col-lg-8 col-12">
      <Button variant="link" className="text-left p-0">
        {submissionTitle}
      </Button>
    </div>
  );
};

const getSubmissionHeadingContent = (
  submissionTitle,
  isConfidential,
  submissionAttachments
) => {
  var submissionUrl,
    submissionMetadataContent = null;

  if (submissionAttachments.length == 1) {
    submissionMetadataContent = getFileMetadataContent(
      isConfidential,
      submissionAttachments[0].file_type,
      submissionAttachments[0].file_size
    );
    submissionUrl = submissionAttachments[0].url;
  } else if (submissionAttachments.length >= 1) {
    submissionMetadataContent = (
      <div className="col-lg-3 text-lg-right col-10 text-left text-muted">
        ({submissionAttachments.length} Files)
      </div>
    );
    submissionUrl = null;
  }

  const submissionTitleContent = getTitleContent(
    isConfidential,
    submissionTitle,
    submissionUrl
  );

  return (
    <>
      {submissionTitleContent != null && submissionTitleContent}
      {submissionMetadataContent != null && submissionMetadataContent}
    </>
  );
};

const getSubmissionSubTitleContent = (submissionSubTitle, key) => {
  return (
    <div key={key} className="text-muted row justify-content-between">
      <div className="col-lg-8 col-12">
        <i style={{ fontSize: "0.85rem" }}>{submissionSubTitle}</i>
      </div>
    </div>
  );
};

const getAdditionalAttachmentsContent = (attachment_data) => {
  const attachmentContent = attachment_data.map((data, key) => {
    var attachment_title = data.title;
    if (!data.title) {
      // If for some reason `data.title` doesn't exist.
      attachment_title = "Submission Document";
      if (data.attachment_order >= 1) {
        attachment_title = `Attachment ${data.attachment_order}`;
      }
    }
    return (
      <div className="row justify-content-between" key={key}>
        <div className="col-lg-8 col-12">
          <a target="_blank" rel="noopener noreferrer" href={data.url}>
            {attachment_title}
          </a>
        </div>
        {
          // If there are additional attachments, it can't be confidential,
          // but the client might just surprise me!
          getFileMetadataContent(false, data.file_type, data.file_size)
        }
        <div className="col-1"></div>
      </div>
    );
  });

  return <Card.Body className="mb-2 pl-3">{attachmentContent}</Card.Body>;
};

export const SubmissionRow = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    submissionTitle,
    submissionSubTitle,
    submissionAttachments,
    isConfidential,
    eventKey,
  } = props;

  const getGenericSubmissionRow = (showChevron) => {
    const chevronToggle = (
      <button className="btn btn-link p-0 align-baseline">
        {open ? <ChevronBarContract size="18" /> : <ChevronDown size="18" />}
      </button>
    );
    return (
      <>
        <div className="row justify-content-lg-between">
          {getSubmissionHeadingContent(
            submissionTitle,
            isConfidential,
            submissionAttachments
          )}
          <div className="col-1 text-left">{showChevron && chevronToggle}</div>
        </div>
        {submissionSubTitle && getSubmissionSubTitleContent(submissionSubTitle)}
      </>
    );
  };

  const attachAccordion = (content) => {
    return (
      <Accordion>
        <Card className="pb-3">
          <Accordion.Toggle as={Card} eventKey={eventKey.toString()}>
            <div className="pb-0">{content}</div>
          </Accordion.Toggle>
          <Accordion.Collapse
            eventKey={eventKey.toString()}
            onEntering={handleOpen}
            onExiting={handleClose}
          >
            {getAdditionalAttachmentsContent(submissionAttachments)}
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  if (submissionAttachments.length > 1) {
    return attachAccordion(getGenericSubmissionRow(true));
  }

  return <div className="pb-3">{getGenericSubmissionRow(false)}</div>;
};

const getSubmissionsRowContents = (submissions) => {
  return submissions.map((data) => {
    return (
      <SubmissionRow
        submissionTitle={data.title}
        submissionSubTitle={data.sub_title}
        submissionAttachments={data.attachment_urls}
        isConfidential={data.confidential}
        eventKey={data.id}
        key={data.id}
      ></SubmissionRow>
    );
  });
};

const loadingSubmissionsContent = (
  <div className="d-flex justify-content-center" style={{ padding: "8rem 0" }}>
    <Spinner className="text-center" animation="border" />
  </div>
);

const buildSubmissionsInfoTextContent = (text) => {
  return <div className="mb-1">{text}</div>;
};

const PaginatedSubmissions = (props) => {
  const { submissionCategoryGetParam, itemsPerPage } = props;
  const [submissions, setSubmissions] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
  };

  useEffect(() => {
    let fetchHeaders = {};
    if (process.env.GATSBY_WSIA_BACKEND_API_TOKEN) {
      fetchHeaders = {
        Authorization: `Token ${process.env.GATSBY_WSIA_BACKEND_API_TOKEN}`,
      };
    }
    const backendUrl = `${process.env.GATSBY_WSIA_BACKEND_BASE_URL}/api/submissions/`;
    axios
      .get(backendUrl, {
        params: {
          category: submissionCategoryGetParam,
          page: pageOffset + 1,
          page_size: itemsPerPage,
        },
        headers: fetchHeaders,
      })
      .then((response) => {
        setSubmissions(response.data.results);
        setPageCount(Math.ceil(response.data.count / itemsPerPage));
        setLoading(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((_) => {
        setApiError(true);
        setSubmissions([]);
        setLoading(false);
      });
  }, [pageOffset, itemsPerPage]);

  var submissionEntryContent = buildSubmissionsInfoTextContent(
    "There are no submissions."
  );
  if (loading) {
    submissionEntryContent = loadingSubmissionsContent;
  } else if (apiError) {
    submissionEntryContent = buildSubmissionsInfoTextContent(
      "There were issues fetching submissions."
    );
  } else if (submissions.length) {
    submissionEntryContent = getSubmissionsRowContents(submissions);
  }

  return (
    <>
      <div className="container submissions">
        {submissionEntryContent}
        <ReactPaginate
          {...REACT_PAGINATION_STYLES}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

PaginatedSubmissions.propTypes = {
  submissionCategoryGetParam: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

PaginatedSubmissions.defaultProps = {
  itemsPerPage: 20,
};

export default PaginatedSubmissions;
