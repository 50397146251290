import React, { useContext, useEffect, useRef } from "react";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";

import { MapContext, types } from "../MapContext";

const Geocoder = (props) => {
  const { searchBoundary } = props;
  const bbox = [
    searchBoundary.minX,
    searchBoundary.minY,
    searchBoundary.maxX,
    searchBoundary.maxY,
  ];
  const [mapState, setMapState] = useContext(MapContext);
  const geocoderRef = useRef();

  useEffect(() => {
    if (mapState.loaded && !mapState.geocoderCreated) {
      const geocoder = new MapboxGeocoder({
        accessToken: process.env.GATSBY_MAPBOX_ACCESS_TOKEN,
        mapboxgl: mapboxgl,
        placeholder: "Address search",
        bbox: bbox,
      });
      geocoder.onAdd(mapState.map);
      geocoder.addTo(geocoderRef.current);
      setMapState({ type: types.SET_GEOCODER_CREATED });
    }
  }, [mapState.loaded, mapState.geocoderCreated, setMapState, bbox]);

  return <div style={{ maxWidth: "270px" }} ref={geocoderRef} />;
};

Geocoder.propTypes = {
  searchBoundary: PropTypes.shape({
    minX: PropTypes.number.isRequired,
    minY: PropTypes.number.isRequired,
    maxX: PropTypes.number.isRequired,
    maxY: PropTypes.number.isRequired,
  }).isRequired,
};

export default Geocoder;
