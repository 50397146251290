import {
  DELETE_PAGE,
  DELETE_ROW,
  INCREMENT_LIGHTBOX_KEY,
  SET_ACTIVE_COMPONENT_TYPE,
  SET_ACTIVE_PAGE_NAME,
  SET_ACTIVE_PAGE_SECTION,
  SET_ACTIVE_ROW_ID,
  SET_ACTIVE_SCENE_ID,
  SET_LAYER_VISIBILITY,
  SET_LIGHTBOX_SOURCE_INDEX,
  SET_LIGHTBOX_SOURCES,
  SET_ACTIVE_MODAL,
  SET_MODAL_TEMPLATES,
  SET_PAGE_CONFIG,
  SET_PAGES_CONFIG,
  SET_TOPIC_LAYERS,
  TOGGLE_LIGHTBOX_STATE,
  HIDE_FEEDBACK_PROMPT,
} from "../constants/ActionTypes";

// Action creators for the Lightbox state.
const incrementLightboxKey = () => ({
  type: INCREMENT_LIGHTBOX_KEY,
});

const setLightboxSourceIndex = (sourceIndex) => ({
  type: SET_LIGHTBOX_SOURCE_INDEX,
  sourceIndex,
});

const setLightboxSources = (sources) => ({
  type: SET_LIGHTBOX_SOURCES,
  sources,
});

const toggleLightboxState = () => ({
  type: TOGGLE_LIGHTBOX_STATE,
});

// Action creators for the Modal state.
const setActiveModal = (modalId, mdxProps) => ({
  type: SET_ACTIVE_MODAL,
  payload: { modalId, mdxProps },
});

const setModalTemplates = (modalTemplates) => ({
  type: SET_MODAL_TEMPLATES,
  modalTemplates,
});

// Action creators for the interactive map
const setLayerVisibility = (layerId, visible) => ({
  type: SET_LAYER_VISIBILITY,
  payload: { layerId, visible },
});

const setTopicLayers = (layerIds) => ({
  type: SET_TOPIC_LAYERS,
  layerIds,
});

// Action creators for the Page section state.
const setActivePageSection = (activeSectionId) => ({
  type: SET_ACTIVE_PAGE_SECTION,
  activeSectionId,
});

// Action creators for the Virtual Consultation Room state.
const setActiveSceneId = (sceneId) => ({
  type: SET_ACTIVE_SCENE_ID,
  sceneId,
});

// Action creators for the Dynamic page creation state.
const deletePage = (pageName) => ({
  type: DELETE_PAGE,
  pageName,
});

const deleteRow = (pageName, rowId) => ({
  type: DELETE_ROW,
  pageName,
  rowId,
});

const setActiveComponentType = (activeComponentType) => ({
  type: SET_ACTIVE_COMPONENT_TYPE,
  activeComponentType,
});

const setActivePageName = (activePageName) => ({
  type: SET_ACTIVE_PAGE_NAME,
  activePageName,
});

const setActiveRowId = (activeRowId) => ({
  type: SET_ACTIVE_ROW_ID,
  activeRowId,
});

const setPageConfig = (pageName, pageConfig) => ({
  type: SET_PAGE_CONFIG,
  pageName,
  pageConfig,
});

const setPagesConfig = (pages) => ({
  type: SET_PAGES_CONFIG,
  pages,
});

const hideFeedbackPrompt = () => ({
  type: HIDE_FEEDBACK_PROMPT,
});

export {
  deletePage,
  deleteRow,
  hideFeedbackPrompt,
  incrementLightboxKey,
  setActiveComponentType,
  setActivePageName,
  setActivePageSection,
  setActiveRowId,
  setActiveSceneId,
  setLayerVisibility,
  setLightboxSourceIndex,
  setLightboxSources,
  setActiveModal,
  setModalTemplates,
  setPageConfig,
  setPagesConfig,
  setTopicLayers,
  toggleLightboxState,
};
