// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-draft-eis-js": () => import("./../../../src/pages/digital-draft-eis.js" /* webpackChunkName: "component---src-pages-digital-draft-eis-js" */),
  "component---src-pages-download-the-draft-eis-js": () => import("./../../../src/pages/download-the-draft-eis.js" /* webpackChunkName: "component---src-pages-download-the-draft-eis-js" */),
  "component---src-pages-download-the-eis-js": () => import("./../../../src/pages/download-the-eis.js" /* webpackChunkName: "component---src-pages-download-the-eis-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../../../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-submissions-jsx": () => import("./../../../src/pages/submissions.jsx" /* webpackChunkName: "component---src-pages-submissions-jsx" */)
}

