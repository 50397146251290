import React, { useCallback, useEffect, useState } from "react";

import { Button, Form } from "react-bootstrap";

const SubmissionForm = ({ submitForm }) => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      // TODO actually submit the form.
      form.reset();
      submitForm(formData);
      setValidated(false);
    }
  };

  return (
    <Form
      className="text-left"
      validated={validated}
      onSubmit={handleSubmit}
      noValidate
    >
      <Form.Group controlId="feedback">
        <Form.Label>Please enter your comment or query</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          style={{ fontSize: 12 }}
          onChange={(event) => handleChange("feedback", event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="needs-response">
        <Form.Check
          type="checkbox"
          label="Select if you need a personal response"
          onChange={(event) =>
            handleChange("needsResponse", event.target.checked)
          }
        />
      </Form.Group>

      <Form.Group controlId="dont-publish">
        <Form.Check
          type="checkbox"
          label="Select if you do not want your question published"
          onChange={(event) =>
            handleChange("dontPublish", event.target.checked)
          }
        />
      </Form.Group>

      <Button type="submit" block>
        Submit
      </Button>
    </Form>
  );
};

const SubmissionFeedbackDemo = ({ formData }) => {
  const [showThankYou, setShowThankYou] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => setShowThankYou(false), 3000);

    // Clear the timeout when the component is unmounted.
    return () => {
      clearTimeout(delay);
    };
  }, []);

  return showThankYou ? (
    <p>Thank you for your submission</p>
  ) : (
    <div className="text-left" style={{ fontSize: 12 }}>
      <h5>Anon asked:</h5>
      <p>{formData.feedback}</p>
      <h5>Our answer:</h5>
      <p>
        Thank you for your feedback. The Project is avoiding the area and has
        strict rules about working in protected areas, you can read more about
        it{" "}
        <strong>
          <a href="/environmental-impact-statement">here</a>
        </strong>
        .
      </p>
    </div>
  );
};

const UserSubmissionPopup = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedData, setSubmittedData] = useState({});

  const submitForm = useCallback(
    (formData) => {
      setIsSubmitted(!isSubmitted);
      setSubmittedData(formData);
    },
    [isSubmitted, setIsSubmitted]
  );

  return (
    <>
      {isSubmitted ? (
        <SubmissionFeedbackDemo formData={submittedData} />
      ) : (
        <SubmissionForm submitForm={submitForm} />
      )}
    </>
  );
};

export default UserSubmissionPopup;
