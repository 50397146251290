/**
 * A simple wrapper component to add a link tag around any other component.
 */

import React from "react";

import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";

import withModalTrigger from "components/Modals/ModalTrigger";

import { requiredLinkPropsCheck } from "./common";

const Link = withModalTrigger((props) => {
  if (props.targetModalId) {
    return (
      <span
        style={props.style}
        onClick={props.setActiveModal}
        onKeyDown={(event) =>
          event.key === "Enter" ? props.setActiveModal() : undefined
        }
        role="button"
        tabIndex={0}
      >
        {props.children}
      </span>
    );
  }

  if (props.isExternal) {
    return (
      <a
        href={props.path}
        target={props.target}
        className={props.className}
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    );
  }

  return (
    <GatsbyLink
      style={props.style}
      to={props.path}
      activeClassName="active"
      className={props.className}
    >
      {props.children}
    </GatsbyLink>
  );
});

Link.defaultProps = {
  target: "_blank",
};

Link.propTypes = {
  className: PropTypes.string,
  path: requiredLinkPropsCheck,
  targetModalId: requiredLinkPropsCheck,
  isExternal: PropTypes.bool,
  style: PropTypes.object,
  target: PropTypes.oneOf(["_self", "_blank", "_parent", "_top"]),
};

export default Link;
