import React from "react";

import Alert from "react-bootstrap/Alert";

const ClosedSubmissionAlert = () => {
  return (
    <Alert variant="info" className={"text-body"}>
      The consultation period for the draft Environmental Impact Statement,
      including the draft noise insulation and property acquisition policy
      closed on 31 January 2024.{" "}
      <a href="/submissions" style={{ textDecoration: "underline" }}>
        Submissions
      </a>{" "}
      received during the consultation period are currently being considered.
    </Alert>
  );
};

export default ClosedSubmissionAlert;
