import React from "react";

import { Col, Form } from "react-bootstrap";

import { DEFAULT_CHOICE } from "../types";

import ThemeColourChoiceField from "./ThemeColourChoiceField";
import { AVAILABLE_ICONS } from "./utils";

const IconField = ({ onFieldChange }) => {
  return (
    <Form.Row>
      <Form.Group as={Col} controlId="iconType">
        <Form.Label>Icon name</Form.Label>
        <Form.Control
          as="select"
          defaultValue=""
          custom
          required
          onChange={(event) => onFieldChange("iconType", event.target.value)}
        >
          <option value="" disabled hidden>
            {DEFAULT_CHOICE}
          </option>
          {AVAILABLE_ICONS.map((iconName, index) => {
            return <option key={index}>{iconName}</option>;
          })}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} controlId="iconSize">
        <Form.Label>Icon size</Form.Label>
        <Form.Control
          type="number"
          placeholder={125}
          required
          onChange={(event) => onFieldChange("iconSize", event.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>

      <ThemeColourChoiceField
        as={Col}
        controlId="iconColour"
        className="px-1"
        label="Icon colour"
        changeFunction={(value) => onFieldChange("iconColour", value)}
      />
    </Form.Row>
  );
};

export default IconField;
