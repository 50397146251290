// Supported components must define a component that defines the form fields
// (form state is managed by this component). They also must provided a function
// that returns a `moduleProps` object when given the `formData`.
import {
  CardImageLinkForm,
  getModuleProps as getCardImageLinkModuleProps,
} from "./CardImageLinkForm";
import {
  getModuleProps as getImageLinkModuleProps,
  ImageLinkForm,
} from "./ImageLinkForm";

export const COMPONENT_FORM_MAPPING = {
  CardImageLink: {
    form: CardImageLinkForm,
    propFunction: getCardImageLinkModuleProps,
    title: "Card Image Link",
  },
  ImageLink: {
    form: ImageLinkForm,
    propFunction: getImageLinkModuleProps,
    title: "Image Link",
  },
};
