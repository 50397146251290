import React from "react";

import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MdxHotspotIcon = (props) => {
  const handleKeyPress = (event) => {
    // Toggle the hotspot when the Enter key is pressed.
    if (event.key === "Enter") {
      props.onClick();
    }
  };

  const iconContent = (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/no-static-element-interactions
    <span
      onClick={
        props.triggerOnClick && props.onClick
          ? () => props.onClick()
          : undefined
      }
      onKeyDown={
        props.triggerOnClick && props.onClick
          ? (event) => handleKeyPress(event)
          : undefined
      }
      className="hotspot-icon"
    >
      {props.children}
    </span>
  );

  if (props.tooltipText) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip className="mb-1" id={`tooltip-${props.name}`}>
            {props.tooltipText}
          </Tooltip>
        }
      >
        {iconContent}
      </OverlayTrigger>
    );
  }

  return <>{iconContent}</>;
};

MdxHotspotIcon.propTypes = {
  tooltipText: PropTypes.string,
  onClick: PropTypes.func,
  triggerOnClick: PropTypes.bool,
};

MdxHotspotIcon.defaultProps = {
  triggerOnClick: true,
};

export default MdxHotspotIcon;
