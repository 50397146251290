import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import ContentImage from "components/Images/ContentImage";
import DocButtonLink from "components/Links/DocButtonLink";
import { FrequentlyAskedQuestion } from "components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import * as React from 'react';
export default {
  Accordion,
  Container,
  Table,
  ContentImage,
  DocButtonLink,
  FrequentlyAskedQuestion,
  React
};