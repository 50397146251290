import React, { createContext, useReducer } from "react";

import PropTypes from "prop-types";

export const MapContext = createContext();

const initialState = {
  map: {},
  loaded: false,
  geocoderCreated: false,
};

export const types = {
  SET_MAP: "SET_MAP",
  SET_MAP_LOADED: "SET_MAP_LOADED",
  SET_GEOCODER_CREATED: "SET_GEOCODER_CREATED",
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_MAP:
      return {
        ...state,
        map: action.payload,
      };
    case types.SET_MAP_LOADED:
      return {
        ...state,
        loaded: true,
      };
    case types.SET_GEOCODER_CREATED:
      return {
        ...state,
        geocoderCreated: true,
      };
    default:
      return state;
  }
};

export const MapContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MapContext.Provider value={[state, dispatch]}>
      {props.children}
    </MapContext.Provider>
  );
};

MapContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
