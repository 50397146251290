/**
 * A simple thumbnail that triggers the site wide Lightbox using Redux. Returns
 * a link if noLightbox specified
 */
import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { PlayFill } from "react-bootstrap-icons";
import Col from "react-bootstrap/Col";

import withLightboxTrigger from "./LightboxTrigger";
import { thumbnailProps } from "./types";

const DocumentLink = (props) => {
  const pdfName = props.lightboxSources[props.startingLightboxIndex].pdfName;
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`/pdf-documents/${pdfName}.pdf`}
      className={props.isOpaqueUntilHover ? "thumbnail-image-holder" : ""}
    >
      {props.children}
    </a>
  );
};

const ThumbnailBody = (props) => {
  if (props.isDocumentLink) {
    return <DocumentLink {...props}>{props.children}</DocumentLink>;
  }

  // Gatsby-image doesn't provide an `onClick` handler for the `Img` component.
  return (
    <div
      onClick={props.triggerLightbox}
      onKeyDown={(event) =>
        event.key === "Enter" ? props.triggerLightbox() : undefined
      }
      role="button"
      tabIndex={0}
      className={props.isOpaqueUntilHover ? "thumbnail-image-holder" : ""}
    >
      {props.children}
    </div>
  );
};

const Thumbnail = withLightboxTrigger((props) => {
  const data = useStaticQuery(graphql`
    query {
      thumbnails: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "thumbnails" }
          sourceInstanceName: { eq: "images" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 450) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const image = data.thumbnails.edges.find(
    ({ node }) => node.name === props.fileName
  );

  if (image === undefined) {
    // Don't render anything if the given fileName doesn't match any files.
    return null;
  }

  const { aspectRatio } = props;
  if (aspectRatio) {
    image.node.childImageSharp.fluid.aspectRatio = aspectRatio;
  }

  return (
    <Col {...props.columnProps}>
      <ThumbnailBody {...props}>
        <>
          <Img fluid={image.node.childImageSharp.fluid} alt={image.node.name} />
          {props.isForVideo && (
            <PlayFill className="text-primary thumbnail-image-icon-overlay" />
          )}
          {props.hoverText && (
            <div className="thumbnail-image-hover-text bg-primary">
              {props.hoverText}
            </div>
          )}
        </>
      </ThumbnailBody>
    </Col>
  );
});

Thumbnail.propTypes = thumbnailProps;

Thumbnail.defaultProps = {
  columnProps: { xs: 12 },
  isOpaqueUntilHover: true,
  startingLightboxIndex: 0,
};

export default Thumbnail;
