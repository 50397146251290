import React from "react";

import ReactDOM from "react-dom";

import TiltButton from "./TiltButton";
import UserPOIButton from "./UserPOIButton";

class MapControl {
  constructor(component) {
    this.component = component;
  }

  onAdd(map) {
    const containerNode = document.createElement("div");
    this._map = map;
    this._container = containerNode;
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    const MapControlButton = this.component;
    ReactDOM.render(<MapControlButton map={map} />, containerNode);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export class TiltControl extends MapControl {
  constructor() {
    super(TiltButton);
  }
}

export class UserPOIControl extends MapControl {
  constructor() {
    super(UserPOIButton);
  }
}
