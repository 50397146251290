import React from "react";

import { Col, Form } from "react-bootstrap";

import { DEFAULT_CHOICE } from "../types";

import { LINK, MODAL } from "./utils";

const UrlPropsField = ({ formData, onFieldChange }) => {
  return (
    <Form.Row>
      <Form.Group as={Col} controlId="linkType">
        <Form.Label>Link type</Form.Label>
        <Form.Control
          as="select"
          required
          custom
          defaultValue=""
          onChange={(event) => onFieldChange("linkType", event.target.value)}
        >
          <option value="" disabled hidden>
            {DEFAULT_CHOICE}
          </option>
          <option>{LINK}</option>
          <option>{MODAL}</option>
        </Form.Control>
      </Form.Group>

      {formData.linkType === LINK && (
        <>
          <Form.Group as={Col} controlId="path">
            <Form.Label>Path</Form.Label>
            <Form.Control
              type="text"
              placeholder="https://www.google.com"
              required
              onChange={(event) => onFieldChange("path", event.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="isExternal">
            <Form.Label>Is external?</Form.Label>
            <Form.Check
              type="checkbox"
              onChange={(event) =>
                onFieldChange("isExternal", event.target.checked)
              }
            />
          </Form.Group>
        </>
      )}

      {formData.linkType === MODAL && (
        <>
          <Form.Group as={Col} controlId="targetModalId">
            <Form.Label>Modal id</Form.Label>
            <Form.Control
              type="text"
              placeholder="share-modal"
              required
              onChange={(event) =>
                onFieldChange("targetModalId", event.target.value)
              }
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
    </Form.Row>
  );
};

export default UrlPropsField;
