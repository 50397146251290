import React from "react";

import PropTypes from "prop-types";
import { XCircleFill } from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";

import { featureProps } from "../types";

import { hidePopupContent, popupContentClass } from "./index";

const LayerPopups = ({ featureProps, popupRef, layerId }) => {
  const layerPopupElements = featureProps.map((feature) => {
    const PopupModule = feature.module;
    const { featureId, moduleProps } = feature;
    return (
      <div
        className={popupContentClass}
        id={featureId}
        data-layerid={layerId}
        key={featureId}
        style={{ width: "150px" }}
      >
        <Nav.Link
          className="p-0 text-light close-mapbox-popup-btn"
          onClick={() => hidePopupContent(featureId, popupRef)}
        >
          <XCircleFill size={18} />
        </Nav.Link>
        <PopupModule {...moduleProps} />
      </div>
    );
  });

  return <>{layerPopupElements}</>;
};

LayerPopups.propTypes = {
  featureProps: featureProps,
  popupRef: PropTypes.object.isRequired,
  layerId: PropTypes.string.isRequired,
};

export default LayerPopups;
