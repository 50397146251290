import * as BootstrapIcons from "react-bootstrap-icons";

export const AVAILABLE_ICONS = Object.keys(BootstrapIcons);

export const ICON_TYPE = "icon";
export const IMAGE_TYPE = "image";
export const LINK = "Link";
export const MODAL = "Modal";

export const getUrlProperties = (formData) => {
  if (formData.linkType === LINK) {
    return {
      path: formData.path,
      ...(formData.isExternal !== undefined && {
        isExternal: formData.isExternal,
      }),
    };
  }
  return { targetModalId: formData.targetModalId };
};

export const getImageStyleProperties = (formData) => {
  return {
    height: parseInt(formData.imageHeight, 10),
    width: parseInt(formData.imageWidth, 10),
  };
};
