import React from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { GeoAlt, Clock, CalendarEvent, CardText } from "react-bootstrap-icons";
import Card from "react-bootstrap/Card";

import { formatEventDateTime } from "components/EventCard/utils";

import { withImageData } from "../Links/common";

const DATE_DISPLAY_FORMAT = "cccc, d MMMM yyyy"; // Saturday, 22 July 2023
const TIME_DISPLAY_FORMAT = "h:mma"; // 12:00AM

const getAbbreviatedSydneyTzName = (datetimeNaive) => {
  return DateTime.fromISO(datetimeNaive)
    .setZone("Australia/Sydney")
    .toFormat("ZZZZZ")
    .match(/[A-Z]/g)
    .join("");
};

const EventCard = withImageData((props) => {
  const cardContent = (
    <Card>
      <Card.Body>
        <Card.Title style={props.eventTitle.style} className="mb-1">
          {props.eventTitle.text}
        </Card.Title>

        {props.eventDateTimeNaive && (
          <div className="event-card-icon-text">
            <CalendarEvent className="mr-2" />
            <Card.Text style={props.eventDateTimeNaive.style}>
              {formatEventDateTime(
                [
                  props.eventDateTimeNaive.startDate,
                  props.eventDateTimeNaive.endDate,
                ],
                DATE_DISPLAY_FORMAT
              )}
            </Card.Text>
          </div>
        )}

        {props.eventDateTimeNaive && (
          <div className="event-card-icon-text">
            <Clock className="mr-2 event-card-icon" />
            <Card.Text style={props.eventDateTimeNaive.style}>
              {formatEventDateTime(
                [
                  props.eventDateTimeNaive.startTime,
                  props.eventDateTimeNaive.endTime,
                ],
                TIME_DISPLAY_FORMAT
              ).toLowerCase()}{" "}
              {/* This displays an approximate TZ name - so be cautious! */}
              {getAbbreviatedSydneyTzName(props.eventDateTimeNaive.startDate)}
            </Card.Text>
          </div>
        )}

        {props.eventDescription && (
          <div className="event-card-icon-text">
            <CardText className="mr-2 event-card-icon" />
            <Card.Text style={props.eventDescription.style}>
              {props.eventDescription.text}
            </Card.Text>
          </div>
        )}

        {props.eventLocation && (
          <div className="event-card-icon-text">
            <GeoAlt className="mr-2 event-card-icon" />
            <Card.Text style={props.eventLocation.style}>
              {props.eventLocation.text}
            </Card.Text>
          </div>
        )}
      </Card.Body>
    </Card>
  );

  return <div className="event-card">{cardContent}</div>;
});

EventCard.propTypes = {
  eventTitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }).isRequired,
  eventDescription: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  eventLocation: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  eventDateTimeNaive: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string,
    style: PropTypes.string,
  }),
};

export default EventCard;
