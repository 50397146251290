/*
Amplify has been used with Authorisation provider AWS Cognito
See docs here https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/
Full config options https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
*/

const cookieExpireMinutes =
  process.env.GATSBY_AWS_COGNITO_COOKIE_TIMEOUT_MINUTES || 60;

const awsConfig = {
  Auth: {
    // Amazon Cognito Region
    region: process.env.GATSBY_AWS_COGNITO_REGION,

    // Amazon Cognito User Pool ID
    userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.GATSBY_AWS_COGNITO_CLIENT_ID,

    mandatorySignIn: true,

    // REQUIRED to set cookie timeout on app
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.GATSBY_AWS_COGNITO_COOKIE_DOMAIN,
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: parseInt(cookieExpireMinutes) / 24 / 60,
      // OPTIONAL - Cookie secure flag
      // If localhost this must be false else true (https).
      secure: process.env.GATSBY_AWS_COGNITO_COOKIE_DOMAIN !== "localhost",
    },
  },
};

export default awsConfig;
