export const popupContainerId = "popupContainerNodeId";
export const popupContentClass = "ocp-mapbox-feature-popup";

const selectCoordinates = (evt, coordinates) => {
  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.
  while (Math.abs(evt.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += evt.lngLat.lng > coordinates[0] ? 360 : -360;
  }
  return coordinates;
};

const displayPopupContent = (evt, popupRef, map) => {
  returnPopupNodeToContainer(popupRef);
  const feature = evt.features[0];
  const coordinates = feature.geometry.coordinates.slice();
  const properties = feature.properties;

  const pointLngLat = selectCoordinates(evt, coordinates);

  const popupNode = document.getElementById(properties.id);
  if (popupNode) {
    const popup = popupRef.current
      .setLngLat(pointLngLat)
      .setDOMContent(popupNode);

    popup.addTo(map);
  }
};

const getPopupElementDetails = (popupRef) => {
  const popupElement = popupRef.current.getElement();
  const node = popupElement.querySelectorAll(`.${popupContentClass}`)[0];
  return {
    id: node.id,
    popupLayerId: node.getAttribute("data-layerid"),
  };
};

const returnPopupNodeToContainer = (popupRef) => {
  if (popupRef.current.isOpen()) {
    const { id } = getPopupElementDetails(popupRef);
    hidePopupContent(id, popupRef);
  }
};

export const hidePopupContent = (popupId, popupRef) => {
  const popupContent = document.getElementById(popupId);
  if (popupContent) {
    const popupContainer = document.getElementById(popupContainerId);
    popupContainer.appendChild(popupContent);
    popupRef.current.remove();
  }
};

const displayOnHover = (map, config, popupRef) => {
  const { layerId } = config;
  map.on("mouseenter", layerId, (evt) => {
    if (evt.features.length) {
      map.getCanvas().style.cursor = "pointer";
      displayPopupContent(evt, popupRef, map);
    }
  });
  map.on("mouseleave", layerId, () => {
    map.getCanvas().style.cursor = "";
    hidePopupContent(config.id, popupRef);
  });
};

const displayOnClick = (map, config, popupRef) => {
  const { layerId } = config;
  map.on("mouseenter", layerId, (evt) => {
    if (evt.features.length) {
      map.getCanvas().style.cursor = "pointer";
    }
  });
  map.on("mouseleave", layerId, () => {
    map.getCanvas().style.cursor = "";
  });
  map.on("click", layerId, (evt) => {
    displayPopupContent(evt, popupRef, map);
  });
};

const closePopupOnLayerOff = (map, layerId, popupRef) => {
  map.on("data", (evt) => {
    if (evt.dataType === "style" && popupRef.current.isOpen()) {
      const { id, popupLayerId } = getPopupElementDetails(popupRef);
      if (
        popupLayerId === layerId &&
        map.getLayoutProperty(layerId, "visibility") === "none"
      ) {
        hidePopupContent(id, popupRef);
      }
    }
  });
};

export const setPopups = (map, popupConfig, popupRef) => {
  popupConfig.forEach((config) => {
    closePopupOnLayerOff(map, config.layerId, popupRef);
    switch (config.mouseEvent) {
      case "hover":
        displayOnHover(map, config, popupRef);
        break;
      case "click":
        displayOnClick(map, config, popupRef);
        break;
      default:
        displayOnClick(map, config, popupRef);
    }
  });
};
