import React from "react";

import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import Link from "components/Links/Link";

const withIconImageData = (WrappedComponent) => {
  return function WrappedWithImageData(props) {
    return (
      <StaticQuery
        query={graphql`
          query IconImages {
            images: allFile(
              filter: {
                extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
                sourceInstanceName: { eq: "images" }
                relativeDirectory: { eq: "logos" }
              }
            ) {
              edges {
                node {
                  id
                  name
                  extension
                  publicURL
                  childImageSharp {
                    fixed(width: 240) {
                      ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => <WrappedComponent {...props} imageData={data} />}
      />
    );
  };
};

const NavLogo = withIconImageData((props) => {
  const getNavLogo = () => {
    const image = props.imageData.images.edges.find(({ node }) => {
      return node.name === props.filename;
    });

    if (image.node.extension === "svg") {
      return (
        <img
          src={image.node.publicURL}
          alt={image.node.name}
          style={props.imgStyle}
        />
      );
    }

    return (
      <Img
        fixed={image.node.childImageSharp.fixed}
        alt={image.node.name}
        style={props.imgStyle}
        fadeIn={false}
        loading="eager"
      />
    );
  };

  return (
    <Link path={props.linkHref} isExternal className="nav-logo">
      {getNavLogo()}
    </Link>
  );
});

NavLogo.propTypes = {
  filename: PropTypes.string.isRequired,
  imgStyle: PropTypes.object,
  linkHref: PropTypes.string.isRequired,
};

export default NavLogo;
