import axios from "axios";

export const getStyleDefinition = (username, styleId) => {
  const token = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;
  const url = `https://api.mapbox.com/styles/v1/${username}/${styleId}?access_token=${token}`;
  return axios
    .get(url)
    .then((response) => {
      return response.data.layers;
    })
    .catch(() => {
      return [];
    });
};

export const getStyleParams = (mapboxStyle) => {
  const [username, styleId] = mapboxStyle.split(/[/]/).slice(-2);
  return { username, styleId };
};
