import React from "react";

import { Button } from "react-bootstrap";

import Link from "./Link";

const ButtonLink = (props) => {
  // This is a sneaky syntax to extract a sub-set of properties from an object
  // Explanation here: https://stackoverflow.com/a/39333479
  const buttonProps = (({ variant, block }) => ({ variant, block }))(props);
  return (
    <Link {...props}>
      <Button {...buttonProps}>{props.children}</Button>
    </Link>
  );
};

export default ButtonLink;
