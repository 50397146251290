import React from "react";

import MdxModal from "./MdxModal";

const ModalWrapper = (props) => {
  return (
    <>
      {props.children}
      <MdxModal />
    </>
  );
};

export default ModalWrapper;
