export const generalConfig = {
  googleTranslate: {
    enabled: true,
    languages: [
      { text: "لغة", code: "ar" },
      { text: "语言", code: "zh-CN" },
      { text: "Γλώσσα", code: "el" },
      { text: "Linguaggio", code: "it" },
      { text: "언어", code: "ko" },
      { text: "Ngôn ngữ", code: "vi" },
      { text: "भाषा", code: "hi" },
      { text: "wika", code: "tl" },
    ],
    noiseTool: [
      { text: "Arabic", code: "ar" },
      { text: "Chinese (Simplified)", code: "zh" },
      { text: "Hindi", code: "hi" },
      { text: "Tagalog", code: "tl" },
      { text: "Vietnamese", code: "vi" },
    ],
  },
  hasGoogleAnalytics: true,
};

export const GBMHALayer = [
  {
    id: "greater-blue-mountains",
    "source-layer": "Greater_Blue_Mountains_World_-cwvb4j",
    type: "fill",
    source: {
      type: "vector",
      url: "mapbox://wsp-wsi-eis.djt264a9",
    },
    paint: {
      "fill-pattern": "positive-diagonal-hatch-rgb-0-115-76",
    },
  },
  {
    id: "greater-blue-mountains-outline",
    "source-layer": "Greater_Blue_Mountains_World_-cwvb4j",
    type: "line",
    source: {
      type: "vector",
      url: "mapbox://wsp-wsi-eis.djt264a9",
    },
    paint: {
      "line-color": "rgb(0,115,76)",
    },
  },
];

export const mapConfig = {
  mapSettings: {
    moduleComponentName: "Mapbox",
    mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkj7yuob000c01r2d3sz9wmt",
    customAttribution: "Indicative only, subject to design development",
    controls: {
      enableUserPOI: false,
      enableGeolocate: false,
      enableTiltcontrol: true,
      enableFullScreen: true,
    },
    initialMapState: {
      locationOptions: {
        zoom: 8.5,
        center: [150.7, -33.851],
        pitch: 0,
        bearing: 0,
      },
      resetInitialLocation: true,
      layerOptions: {
        visible: [
          "aboriginal-places-labels",
          "aboriginal-places",
          "existing-airports",
          "wsi-airport-site",
          "greater-blue-mountains",
          "greater-blue-mountains-outline",
        ],
      },
    },
    basemaps: {
      primary: {
        imageName: "primary",
        type: "style",
        id: "mapbox://styles/wsp-wsi-eis/clkj7yuob000c01r2d3sz9wmt",
        // All layers will be placed under this layer so labels from basemap render on top
        basemapLabelLayerId: "admin-1-boundary-bg",
      },
      alternate: {
        imageName: "alternate",
        type: "layer",
        id: "mapbox-satellite-imagery",
        // Alternate basemap layer will be placed under this layer
        insertAfterLayerId: "land-wsi-custom",
      },
    },
  },
  commonLegend: [],
  geocoderConfig: {
    moduleComponentName: "MapboxGeocoder",
    searchBoundary: {
      minX: 140.8,
      minY: -38.2,
      maxX: 153.7,
      maxY: -28.1,
    },
  },
  icons: [
    {
      id: "positive-diagonal-hatch-rgb-0-115-76",
      fileName: "positive-diagonal-hatch-rgb-0-115-76.png",
    },
  ],
  layerControls: [
    {
      ids: ["aboriginal-places-labels", "aboriginal-places"],
      label: "Aboriginal places",
    },
    {
      ids: ["existing-airports"],
      label: "Existing airports",
    },
    {
      ids: ["wsi-airport-site"],
      label: "WSI airport site",
    },
    {
      ids: ["greater-blue-mountains", "greater-blue-mountains-outline"],
      label: "Greater Blue Mountains",
    },
  ],
  layers: [
    {
      id: "aboriginal-places-labels",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkjbx804004x01pmcu3qg0hz",
      "source-layer": "HERITAGE_GOV_AboriginalPlaces-adni5n",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.37uwztz9",
      },
    },
    {
      id: "aboriginal-places",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkjbx804004x01pmcu3qg0hz",
      "source-layer": "HERITAGE_GOV_AboriginalPlaces-adni5n",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.37uwztz9",
      },
    },
    {
      id: "existing-airports",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkjbx804004x01pmcu3qg0hz",
      "source-layer": "TRANSPORT_DCS_Airports_Transp-6h2bh0",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.8aqcvge5",
      },
    },
    {
      id: "wsi-airport-site",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkjbx804004x01pmcu3qg0hz",
      "source-layer": "BOUNDARY_DITRDC_AirportLandBo-czw8nq",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.cd4pbj83",
      },
    },
    {
      id: "mapbox-satellite-imagery",
      type: "raster",
      source: {
        type: "raster",
        url: "mapbox://mapbox.satellite",
        tileSize: 256,
      },
      paint: {
        "raster-opacity": 0.8,
      },
    },
  ],
  layerLegends: {
    "aboriginal-places": [
      {
        legendType: "icon",
        colour: "rgba(168,112,0)",
        text: "Aboriginal Places raised during consultation (NPW Act)",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "rgba(115,0,0)",
        text: "Site of Aboriginal significance",
        value: "CircleFill",
      },
    ],
    "wsi-airport-site": [
      {
        legendType: "fill",
        text: "WSI airport site",
        value: "solid",
        outline: "rgba(255,85,0)",
      },
    ],
    "greater-blue-mountains": [
      {
        legendType: "img",
        text: "Greater Blue Mountains",
        value: "positive-diagonal-hatch-rgb-0-115-76",
      },
    ],
  },
  popups: [],
  sites: [
    {
      id: "WSISites",
      label: "WSI airport site",
      locationOptions: {
        zoom: 12,
        center: [150.722449, -33.888952],
        pitch: 0,
        bearing: 0,
      },
    },
  ],
};

export const INDEX_PATH = "/";
export const CREATE_BASE_PATH = "/create";

// The multi-page pages all have the same navbar. The repetition of the nav config
// would not be required if the base path was used for the multi-page view as the base
// path nav config is the default.
const multiPageNavConfig = {
  items: {
    nonCollapsing: [
      {
        item: "logo",
        filename: "dept-logo-white",
        linkHref: "https://www.infrastructure.gov.au/",
      },
    ],
    collapsing: [
      {
        item: "page-link-noise",
        text: "Noise tool",
        link: "https://wsiflightpaths.aerlabs.com",
        attributes: {
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      {
        item: "page-link",
        text: "Download or view EIS",
        link: "/download-the-eis",
      },
      {
        item: "page-link",
        text: "Resources",
        link: "/resources",
      },
      {
        item: "page-link",
        text: "Submissions",
        link: "/submissions",
      },
      {
        item: "page-link",
        text: "Contact us",
        link: "/contact-us",
      },
    ],
  },
};

export const navConfig = {
  [INDEX_PATH]: {
    title: `Western Sydney International (Nancy-Bird Walton) Airport Airspace and flight path design`,
    hideUntilScroll: false,
    items: multiPageNavConfig.items,
  },
};

export const footerConfig = {
  leftContent: {
    copyrightText: "",
    links: [
      {
        text: "Privacy Statement",
        path: "/privacy-statement",
      },
      {
        text: "Acknowledgement of Country",
        modalId: "intro",
      },
    ],
  },
  rightContent: ["GoogleTranslate"],
};
