/**
 * Mapbox layers can be styled with a manually defined style object or the style object can be
 * requested from mapbox studio using the username and style id. User defined properties will
 * override those fetched from mapbox studio.
 */

import _has from "lodash/has";

import { mapConfig } from "src/app-config";

import { storeBasemapIds, setAlternateBasemapVisibility } from "./basemaps";
import { getStyleDefinition, getStyleParams } from "./common";

// On error use mapbox default styles except fill, mapbox default is solid black.
const defaultStyle = {
  paint: {
    "fill-color": "rgba(108, 122, 137, 0.8)",
    "fill-outline-color": "#000000",
  },
};

const getLayerStyleParams = (layer) => {
  if (!layer.mapboxStyle) {
    return;
  }
  return getStyleParams(layer.mapboxStyle);
};

// One mapbox style can have multiple layer style definitions. Only want to request the style once to
// avoid unnecessary API requests.
const mapboxStyleRequired = (layers) => {
  return layers.reduce((accumulator, layer) => {
    const styleParams = getLayerStyleParams(layer);
    if (_has(styleParams, "styleId")) {
      const { username, styleId } = styleParams;
      accumulator[styleId] = { username };
    }
    return accumulator;
  }, {});
};

const getLayerStyles = async (layers) => {
  const styles = mapboxStyleRequired(layers);
  const styleDefinitions = Object.keys(styles).map(async (styleId) => {
    const { username } = styles[styleId];
    styles[styleId].layers = await getStyleDefinition(username, styleId);
  });
  await Promise.all(styleDefinitions);
  return styles;
};

const styleLookup = (layer, styles) => {
  const styleParams = getLayerStyleParams(layer);
  if (_has(styleParams, "styleId")) {
    const layerStyles = styles[styleParams.styleId].layers;
    const layerStyle = layerStyles.filter((layerStyle) => {
      return layerStyle.id === layer.id;
    })[0];
    return layerStyle || (layer.type === "fill" ? defaultStyle : {});
  }
};

const addLayer = (map, layer, afterLayer) => {
  const { id, source, ...properties } = layer;
  const layerProperties = {
    ...properties,
    source: id,
    id: id,
  };
  map.addSource(id, source);
  // Second property specifies under what layer to insert new layer
  map.addLayer(layerProperties, afterLayer);
};

export const addLayersToMap = async (map, layers) => {
  const basemapConfig = mapConfig.mapSettings.basemaps;
  const styles = await getLayerStyles(layers);
  await storeBasemapIds();
  setAlternateBasemapVisibility(layers).forEach((layer) => {
    const afterLayer =
      layer.id === basemapConfig.alternate.id
        ? basemapConfig.alternate.insertAfterLayerId
        : basemapConfig.primary.basemapLabelLayerId;
    const style = styleLookup(layer, styles);
    addLayer(map, { ...style, ...layer }, afterLayer);
  });
  return;
};

// Check if map has loaded by running map.loaded() at
// specified interval recursively
export const mapLoaded = (map) => {
  let checkCount = 0;
  const maxChecks = 150;
  const checkInterval = 200;

  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      checkCount++;
      if (map.loaded() || checkCount >= maxChecks) {
        resolve();
        clearInterval(intervalId);
        return;
      }
    }, checkInterval);
  });
};
