import React from "react";

import PropTypes from "prop-types";

import Link from "components/Links/Link";

const NavTitle = (props) => {
  return (
    <Link path="/" className="link-title-emphasis nav-page-link">
      {props.title}
    </Link>
  );
};

NavTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default NavTitle;
