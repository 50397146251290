import React from "react";

import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";

const PageLoadingOverlay = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className="page-loading-container d-flex flex-column justify-content-center align-items-center">
      {loading && (
        <Spinner animation="border" variant="primary" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
    </div>
  );
};

PageLoadingOverlay.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default PageLoadingOverlay;
