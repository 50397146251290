import { HIDE_FEEDBACK_PROMPT } from "../constants/ActionTypes";

const initialState = {
  hide: false,
};

const feedback = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_FEEDBACK_PROMPT:
      return {
        ...state,
        hide: true,
      };
    default:
      return state;
  }
};

export default feedback;
