import React from "react";

import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

const getPageTitle = (defaultTitle, pages) => {
  const { pathname } = useLocation();
  const found = pages.filter((pageItem) => pageItem.path === pathname);
  if (found.length > 0) {
    return found[0].title;
  }
  return defaultTitle;
};

const Metadata = () => {
  const query = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          pageTitles {
            path
            title
          }
        }
      }
    }
  `);
  const pageTitle = getPageTitle(
    query.site.siteMetadata.title,
    query.site.siteMetadata.pageTitles
  );
  return (
    <Helmet title={pageTitle}>
      <meta name="description" content={query.site.siteMetadata.description} />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    </Helmet>
  );
};

export default Metadata;
