import _isEmpty from "lodash/isEmpty";
import _isObject from "lodash/isObject";
import PropTypes from "prop-types";

import { lightboxTriggerProps } from "components/Images/types";

export const HOTSPOT = "hotspot";
export const LIGHTBOX = "lightbox";
export const LINK = "link";
export const SCENE = "scene";

export const MdxHotspotPropTypes = {
  name: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  triggerOnClick: PropTypes.bool,
  onTrigger: PropTypes.func.isRequired,
  contentType: PropTypes.oneOf([HOTSPOT, LIGHTBOX, LINK, SCENE]),
  lightboxProps: PropTypes.shape(lightboxTriggerProps),
  linkProps: (props, propName, componentName) => {
    if (props.contentType !== LINK) {
      return;
    }

    const value = props[propName];
    if (_isEmpty(value)) {
      return new Error(
        `${propName} is required in ${componentName} when "contentType" is "${LINK}".`
      );
    }

    if (!_isObject(value)) {
      return new Error(`${propName} must be an object.`);
    }
  },
  targetSceneId: (props, propName, componentName) => {
    if (props.contentType !== SCENE) {
      return;
    }

    const value = props[propName];
    if (_isEmpty(value)) {
      return new Error(
        `${propName} is required in ${componentName} when "contentType" is "${SCENE}".`
      );
    }

    if (typeof value !== "string") {
      return new Error(`${propName} must be a string.`);
    }
  },
  children: (props, propName, componentName) => {
    const value = props[propName];
    if (
      props.contentType === HOTSPOT &&
      (!Array.isArray(value) || value.length !== 2)
    ) {
      return new Error(`
        ${componentName} expects exactly 2 child elements when the
        "contentType" is "${HOTSPOT}", one for the hotspot trigger and one for the
        hotspot content.

        Please fix the MDX template "${props.name}".
      `);
    }
  },
};
