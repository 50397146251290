/**
 * Site wide lightbox that uses Redux for the sources and initial state.
 */
import React from "react";

import FsLightbox from "fslightbox-react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import _isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";

import PDFViewer from "components/PDFViewer/PDFViewer";

const UI_TIMEOUT_MILLISECONDS = 3600000;

const ImageCaption = ({ caption }) => {
  if (!caption) {
    return null;
  }
  return <p className="text-light pt-2 px-1">{caption}</p>;
};

const buildLightboxImageSource = (title, queryNode, index, caption) => {
  // Build Gatsby-Image components for the lightbox using the fluid src so
  // images will size according to the device breakpoints.

  return (
    // FsLightbox requires custom components to be wrapped in HTML tags
    // The width style is required to let the `Img` component choose the
    // appropriately sized image source.
    <div key={index} style={{ width: "100vw" }}>
      <Img fluid={queryNode.childImageSharp.fluid} alt={title} />
      <ImageCaption caption={caption} />
    </div>
  );
};

const buildLightboxPDFSource = (pdfName, index) => {
  return (
    <div
      key={index}
      style={{ width: "100vw", height: "100vh", textAlign: "center" }}
    >
      <PDFViewer pdfName={pdfName} />
    </div>
  );
};

const buildLightboxVideoSource = (title, src, index) => {
  return (
    <iframe
      key={index}
      style={{ width: "100vw", height: "100vh" }}
      title={title}
      src={src}
      frameBorder="0"
      allowFullScreen
    />
  );
};

/**
 * Perform actions when the lightbox slide changes.
 *
 * @param fsLightbox the Lightbox object.
 *
 * Currently used to stop iframe video's from playing when no longer the
 * active slide.
 */
const handleSlideChange = (fsLightbox, sources) => {
  const previousSlideIndex = fsLightbox.stageIndexes.previous;
  const previousSlide = sources[previousSlideIndex];

  if (!_isEmpty(previousSlide) && previousSlide.type === "video") {
    // Change the source of the previous slide component to stop it playing.
    fsLightbox.elements.sources[previousSlideIndex].current.src =
      previousSlide.src;
  }
};

const Lightbox = () => {
  const data = useStaticQuery(graphql`
    query {
      lightboxSources: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "lightbox" }
          sourceInstanceName: { eq: "images" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 5000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  // The sources and customSources props for `FsLightbox` are not reactive.
  // dynamic source lists can be achieved by reloading the component by changing
  // its key. This should be handled by the lightbox trigger function in the
  // triggering component.
  const lightboxKey = useSelector((state) => state.lightbox.lightboxKey);
  const sourceIndex = useSelector((state) => state.lightbox.sourceIndex);
  const sources = useSelector((state) => state.lightbox.sources);
  const toggled = useSelector((state) => state.lightbox.toggled);

  const customSources = [];
  sources.forEach((source, index) => {
    if (source.type === "image") {
      const lightboxSource = data.lightboxSources.edges.find(
        ({ node }) => node.name === source.fileName
      );

      if (lightboxSource) {
        customSources.push(
          buildLightboxImageSource(
            source.title,
            lightboxSource.node,
            index,
            source.caption
          )
        );
      }
    } else if (source.type === "pdf") {
      customSources.push(buildLightboxPDFSource(source.pdfName, index));
    } else {
      customSources.push(
        buildLightboxVideoSource(source.title, source.src, index)
      );
    }
  });

  return (
    <>
      {!_isEmpty(customSources) && (
        <FsLightbox
          key={lightboxKey}
          toggler={toggled}
          sources={[]}
          customSources={customSources}
          sourceIndex={sourceIndex}
          onSlideChange={(fsLightbox) => handleSlideChange(fsLightbox, sources)}
          UIFadeOutTime={UI_TIMEOUT_MILLISECONDS}
        />
      )}
    </>
  );
};

export default Lightbox;
