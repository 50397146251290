import React, { useCallback, useState } from "react";

import mapboxgl from "mapbox-gl";
import { Geo, XCircle } from "react-bootstrap-icons";
import ReactDOM from "react-dom";

import UserSubmissionPopup from "../Popups/UserSubmissionPopup";

const UserPOIButton = ({ map }) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const handleUserPOIMapClick = useCallback(
    (event) => {
      const markerElement = document.createElement("div");
      const popupElement = document.createElement("div");

      ReactDOM.render(<Geo size={18} />, markerElement);
      ReactDOM.render(<UserSubmissionPopup />, popupElement);

      new mapboxgl.Marker(markerElement)
        .setLngLat(event.lngLat)
        .setPopup(new mapboxgl.Popup().setDOMContent(popupElement))
        .addTo(map);

      // Disable User POI adding until the user turns it back on again.
      setIsEnabled(false);
    },
    [setIsEnabled]
  );

  if (isEnabled) {
    // Add the user submission marker and remove the click handler. This enables
    // the marker to be clicked immediately, without a new marker being placed.
    // It also makes the "Make a submission" action something the user can't do
    // willy nilly.
    map.once("click", handleUserPOIMapClick);
  }

  return (
    <button
      type="button"
      className="font-weight-bold"
      aria-label="Toggle between user input mode on and off"
      onClick={() => setIsEnabled(!isEnabled)}
    >
      {isEnabled ? <XCircle size={18} /> : <Geo size={18} />}
    </button>
  );
};

export default UserPOIButton;
