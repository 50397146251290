/**
 * General image component. All images need to be in images/content
 * directory
 */

import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

const ContentImage = ({
  className,
  styles,
  fileName,
  figureNote,
  figureTitle,
}) => {
  const data = useStaticQuery(graphql`
    query {
      content: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "content" }
          sourceInstanceName: { eq: "images" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const image = data.content.edges.find(({ node }) => node.name === fileName);
  if (image === undefined) {
    // Don't render anything if the given fileName doesn't match any files.
    return null;
  }
  return (
    <div className={`px-0 ${className}`} style={styles}>
      <Img fluid={image.node.childImageSharp.fluid} alt={image.node.name} />
      {figureNote && (
        <p className="figure-caption pt-1 font-italic mb-0">{figureNote}</p>
      )}
      {figureTitle && (
        <p className="figure-caption pt-1 font-weight-bold">{figureTitle}</p>
      )}
    </div>
  );
};

ContentImage.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  fileName: PropTypes.string.isRequired,
  figureNote: PropTypes.string,
  figureTitle: PropTypes.string,
};

export default ContentImage;
