import React, { useState } from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { setActiveModal, setActiveRowId, setPageConfig } from "state/actions";

import ThemeColourChoiceField from "./Fields/ThemeColourChoiceField";
import { DEFAULT_CHOICE } from "./types";

const DEMO_ROW_STYLES = {
  display: "flex",
  boxShadow: "inset 0px 0px 10px rgba(0, 0, 0, 0.9)",
  padding: "0.5rem",
  marginBottom: "0.5rem",
  marginTop: "0.5rem",
  minHeight: 200,
};

const DemoComponent = () => {
  return (
    <Col
      xs={4}
      className="m-1 bg-dark d-flex align-content-center align-items-center justify-content-center text-light"
      style={{ width: 100, height: 100 }}
    >
      Component
    </Col>
  );
};

const getInitialFormData = (existingRowConfig) => {
  const existingRowProps = existingRowConfig.rowProps;
  const initialFormData = {};

  initialFormData.centerHorizontal = !!existingRowProps.className.includes(
    "justify-content-center"
  );
  initialFormData.centerVertical = !!existingRowProps.className.includes(
    "align-content-center align-items-center"
  );

  initialFormData.rowColour = DEFAULT_CHOICE;
  const themeMatch = existingRowProps.className.match(/bg-(?<theme>\w+)/);
  if (themeMatch && themeMatch.groups) {
    initialFormData.rowColour = themeMatch.groups.theme;
  }

  initialFormData.noGutters = !!existingRowProps.noGutters;

  return initialFormData;
};

const DynamicRowForm = () => {
  const dispatch = useDispatch();

  const pageName = useSelector((state) => state.dynamicPages.activePageName);
  const pages = useSelector((state) => state.dynamicPages.pages);
  const pageConfig = pages[pageName] || { config: [] };

  const activeRowId = useSelector((state) => state.dynamicPages.activeRowId);
  const existingRowConfig =
    pageConfig.config.find((item) => item.name === activeRowId) || {};

  let initialFormData = {};
  if (activeRowId) {
    initialFormData = getInitialFormData(existingRowConfig);
  }
  const [formData, setFormData] = useState(initialFormData);

  let demoRowClassName = "";
  if (formData.centerHorizontal) {
    demoRowClassName += " justify-content-center";
  }
  if (formData.centerVertical) {
    demoRowClassName += " align-content-center align-items-center";
  }
  if (formData.rowColour && formData.rowColour !== DEFAULT_CHOICE) {
    demoRowClassName += ` bg-${formData.rowColour.toLowerCase()}`;
  }

  const handleChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newPageConfig = { ...pageConfig };
    const newRowConfig = {
      name: activeRowId ? activeRowId : uuidv4(),
      children: existingRowConfig.children || [],
      rowProps: {
        noGutters: formData.noGutters,
        className: demoRowClassName,
        // These styles are to make adding components and editing a row easier,
        // they should not carry through to the final output.
        style: { minHeight: 200 },
      },
      wrapWithContainer: true,
      wrappedRowProps: { className: demoRowClassName },
    };

    if (activeRowId) {
      const foundIndex = pageConfig.config.findIndex(
        (item) => item.name === activeRowId
      );
      newPageConfig.config[foundIndex] = newRowConfig;
    } else {
      newPageConfig.config.push(newRowConfig);
    }
    dispatch(setPageConfig(pageName, newPageConfig));
    dispatch(setActiveModal(null));
    dispatch(setActiveRowId(""));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} controlId="centerHorizontal">
          <Form.Check
            type="switch"
            label="Center horizontally"
            aria-describedby="centerHorizontalHelp"
            defaultChecked={formData.centerHorizontal}
            onChange={(event) =>
              handleChange("centerHorizontal", event.target.checked)
            }
          />
          <Form.Text id="centerHorizontalHelp" muted>
            Center components within the row along the horizontal axis.
          </Form.Text>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="centerVertical">
          <Form.Check
            type="switch"
            label="Center vertically"
            aria-describedby="centerVerticalHelp"
            defaultChecked={formData.centerVertical}
            onChange={(event) =>
              handleChange("centerVertical", event.target.checked)
            }
          />
          <Form.Text id="centerVerticalHelp" muted>
            Center components within the row along the vertical axis.
          </Form.Text>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="noGutters">
          <Form.Check
            type="switch"
            label="Remove gutters"
            aria-describedby="noGuttersHelp"
            defaultChecked={formData.noGutters}
            onChange={(event) =>
              handleChange("noGutters", event.target.checked)
            }
          />
          <Form.Text id="noGuttersHelp" muted>
            Rows overflow their container by a short amount, which are called
            gutters. Removing these gutters will reduce the overall width of the
            row slightly.
          </Form.Text>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <ThemeColourChoiceField
          controlId="rowColour"
          className="px-1"
          label="Background colour"
          value={formData.rowColour}
          changeFunction={(value) => handleChange("rowColour", value)}
        />
      </Form.Row>

      <Container fluid>
        <Row
          className={demoRowClassName}
          style={DEMO_ROW_STYLES}
          noGutters={formData.noGutters}
        >
          <DemoComponent />
          <DemoComponent />
        </Row>
      </Container>

      <Button className="float-right" variant="primary" type="submit">
        {activeRowId ? "Edit row" : "Add row"}
      </Button>
    </Form>
  );
};

export default DynamicRowForm;
