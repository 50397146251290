import React, { useEffect, useRef, useState } from "react";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import _get from "lodash/get";
import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
import "mapbox-gl/dist/mapbox-gl.css";

import BasemapSelector from "components/InteractiveMap/BasemapSelector";
import MapControl from "components/InteractiveMap/MapControl";
import MapLegend from "components/InteractiveMap/MapLegend";
import PageLoadingOverlay from "components/Page/PageLoadingOverlay";

import { initialMapState } from "../types";

import { TiltControl, UserPOIControl } from "./Controls";
import { setInitialVisibleLayers } from "./MapboxActions";
import { setPopups } from "./Popups";
import PopupContainer from "./Popups/PopupContainer";
import { addLayersToMap, mapLoaded } from "./layers";
import { layerDefinition, styledVectorTile, popups, icons } from "./types";

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;

const createGeocoder = (geocoderRef, mapInstance, bbox) => {
  const geocoder = new MapboxGeocoder({
    accessToken: process.env.GATSBY_MAPBOX_ACCESS_TOKEN,
    mapboxgl: mapboxgl,
    placeholder: "Address search",
    bbox: bbox,
  });
  geocoder.onAdd(mapInstance.current);
  geocoder.addTo(geocoderRef.current);
};

const addMapControls = (map, controls) => {
  const {
    enableUserPOI,
    enableGeolocate,
    enableTiltcontrol,
    enableFullScreen,
  } = controls;
  if (enableGeolocate) {
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
      })
    );
  }
  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(
    new mapboxgl.ScaleControl({
      maxWidth: 100,
      unit: "metric",
    }),
    "bottom-right"
  );
  if (enableTiltcontrol) {
    map.addControl(new TiltControl());
  }
  if (enableUserPOI) {
    map.addControl(new UserPOIControl());
  }
  if (enableFullScreen) {
    map.addControl(new mapboxgl.FullscreenControl());
  }
};

const setFallBackIcon = (map, id) => {
  const fallBackIconPath = "/icons/fallback-icon.png";
  map.loadImage(fallBackIconPath, (_error, fallBackIcon) => {
    map.addImage(id, fallBackIcon);
  });
};

const loadIcons = (map, icons) => {
  icons.forEach(({ id, fileName }) => {
    map.loadImage(`/icons/${fileName}`, (error, image) => {
      if (error) {
        setFallBackIcon(map, id);
        return;
      }
      map.addImage(id, image);
    });
  });
};

const initialiseMap = (params) => {
  const {
    initialMapState,
    customAttribution,
    layers,
    mapboxStyle,
    mapContainer,
    popupRef,
    popups,
    setLoading,
    icons,
    mapInstance,
    setMapCreated,
    controls,
    geocoderRef,
    bbox,
  } = params;
  const { locationOptions, layerOptions } = initialMapState;

  mapInstance.current = new mapboxgl.Map({
    ...locationOptions,
    container: mapContainer.current,
    style: mapboxStyle,
    ...(customAttribution && { customAttribution: customAttribution }),
  });

  addMapControls(mapInstance.current, controls);
  createGeocoder(geocoderRef, mapInstance, bbox);

  mapInstance.current.on("load", () => {
    loadIcons(mapInstance.current, icons);
    setPopups(mapInstance.current, popups, popupRef);
    setMapCreated(true);
    addLayersToMap(mapInstance.current, layers)
      .then(() => {
        setInitialVisibleLayers(mapInstance.current, layerOptions.visible);
        return mapLoaded(mapInstance.current);
      })
      .then(() => {
        setLoading(false);
      });
    mapInstance.current.resize();
  });
};

const getInitialLayerVisibility = (initialMapState, layers) => {
  const visibleLayerIds = _get(initialMapState, "layerOptions.visible", []);

  return layers.reduce((accumulator, layer) => {
    accumulator[layer.id] = { visible: visibleLayerIds.includes(layer.id) };
    return accumulator;
  }, {});
};

const Mapbox = (props) => {
  const popupOptions = {
    closeButton: false,
    closeOnClick: false,
    closeOnMove: false,
    className: "mapbox-popup-element",
  };
  const popupRef = useRef(new mapboxgl.Popup(popupOptions));

  // Map
  const mapInstance = useRef(null);
  const mapRef = useRef(null);
  const [mapCreated, setMapCreated] = useState(false);
  const [loading, setLoading] = useState(false);

  // Layers
  const initialLayerVisiblity = getInitialLayerVisibility(
    props.initialMapState,
    props.layers
  );
  const [layerVisibility, setLayerVisibility] = useState(initialLayerVisiblity);

  // Geocoder
  const geocoderRef = useRef();
  const { searchBoundary } = props.geocoderConfig;
  const bbox = [
    searchBoundary.minX,
    searchBoundary.minY,
    searchBoundary.maxX,
    searchBoundary.maxY,
  ];

  const mapConstructors = {
    mapContainer: mapRef,
    popupRef: popupRef,
    initialMapState: props.initialMapState,
    customAttribution: props.customAttribution,
    layers: props.layers,
    mapboxStyle: props.mapboxStyle,
    popups: props.popups,
    setLoading: setLoading,
    icons: props.icons,
    mapInstance: mapInstance,
    setMapCreated: setMapCreated,
    controls: props.controls,
    geocoderRef: geocoderRef,
    bbox: bbox,
  };

  useEffect(() => {
    if (!mapCreated) {
      setLoading(true);
      initialiseMap(mapConstructors);
    }
  }, [mapCreated]);

  const handleSetLayerVisibility = (layerIds, isVisible) => {
    const visibilty = isVisible ? "visible" : "none";
    const updatedVisibility = Object.keys(layerVisibility).reduce(
      (accumulator, layerId) => {
        if (layerIds.includes(layerId)) {
          mapInstance.current.setLayoutProperty(
            layerId,
            "visibility",
            visibilty
          );
          accumulator[layerId] = { visible: isVisible };
        } else {
          accumulator[layerId] = { visible: layerVisibility[layerId].visible };
        }
        return accumulator;
      },
      {}
    );
    setLayerVisibility(updatedVisibility);
  };

  const handleSwitchBasemap = (activate) => {
    ["alternate", "primary"].forEach((key) => {
      const visibilty = activate === key ? "visible" : "none";
      const layerIds = JSON.parse(sessionStorage.getItem(`${key}-basemap`));
      layerIds.forEach((layerId) => {
        mapInstance.current.setLayoutProperty(layerId, "visibility", visibilty);
      });
    });
  };

  const zoomToLocation = (locationOptions) => {
    mapInstance.current.flyTo(locationOptions);
  };

  return (
    <div
      ref={mapRef}
      style={{ height: "500px" }}
      className="interactive-map-container"
    >
      <PageLoadingOverlay loading={loading} />
      <PopupContainer popups={props.popups} popupRef={popupRef} />
      <MapControl
        layerControls={props.layerControls}
        sites={props.sites}
        geocoderConfig={props.geocoderConfig}
        zoomToLocation={zoomToLocation}
        layerVisibility={layerVisibility}
        setLayersVisibility={handleSetLayerVisibility}
      >
        <div style={{ maxWidth: "270px" }} ref={geocoderRef} />
      </MapControl>
      <BasemapSelector
        switchBasemap={handleSwitchBasemap}
        basemaps={props.basemaps}
      />
      <MapLegend
        layers={layerVisibility}
        commonLegend={props.commonLegend}
        layerLegends={props.layerLegends}
      />
    </div>
  );
};

Mapbox.propTypes = {
  initialMapState: initialMapState,
  customAttribution: PropTypes.string.isRequired,
  layers: PropTypes.arrayOf(
    PropTypes.oneOfType([layerDefinition, styledVectorTile])
  ).isRequired,
  mapboxStyle: PropTypes.string.isRequired,
  popups: popups,
  icons: icons,
  layerControls: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  geocoderConfig: PropTypes.object.isRequired,
  basemaps: PropTypes.object.isRequired,
  controls: PropTypes.object.isRequired,
  commonLegend: PropTypes.array.isRequired,
  layerLegends: PropTypes.object.isRequired,
};

export default Mapbox;
