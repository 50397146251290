import React, { useState } from "react";

import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { setPageConfig, setActiveModal } from "state/actions";

const DynamicPageForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  const handleFieldChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      dispatch(
        setPageConfig(formData.pageSlug, {
          pageName: formData.pageName,
          config: [],
        })
      );
      // Close the form modal.
      dispatch(setActiveModal(null));
    }

    setValidated(true);
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit} noValidate>
      <Form.Group controlId="pageName">
        <Form.Label>Page name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the page name"
          onChange={(event) =>
            handleFieldChange("pageName", event.target.value)
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="pageSlug">
        <Form.Label>Page slug</Form.Label>
        <Form.Control
          type="text"
          pattern="^[a-z0-9]+(?:-[a-z0-9]+)*$"
          placeholder="Enter the page URL slug"
          onChange={(event) =>
            handleFieldChange("pageSlug", event.target.value)
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          This field is required. Only letters, numbers, hyphens and underscores
          are allowed. No spaces or special characters are allowed.
        </Form.Control.Feedback>
      </Form.Group>

      <Button variant="primary" type="submit">
        Add page
      </Button>
    </Form>
  );
};

export default DynamicPageForm;
