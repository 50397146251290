import React from "react";

import "@aws-amplify/ui/dist/styles.css";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";

import Footer from "components/Footer/Footer";
import Lightbox from "components/Images/Lightbox";
import ModalWrapper from "components/Modals/ModalWrapper";
import NavBar from "components/Navs/NavBar";
import Metadata from "components/Page/Metadata";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { CREATE_BASE_PATH } from "src/app-config";
import awsConfig from "src/auth/awsConfig";

const enableAwsCognito = process.env.GATSBY_ENABLE_AWS_COGNITO === "true";

if (enableAwsCognito) {
  Amplify.configure(awsConfig);
}

const App = ({ element, props }) => {
  const page = props.uri;
  // Don't display the footer on any dynamic/created page.
  const showNavMenus = !page.startsWith(CREATE_BASE_PATH);

  return (
    <ModalWrapper {...props}>
      <Metadata />
      {showNavMenus && <NavBar page={page} />}
      {element}
      <Lightbox />
      {showNavMenus && <Footer />}
      <ScrollToTop addOutline />
    </ModalWrapper>
  );
};

export default enableAwsCognito ? withAuthenticator(App) : App;
