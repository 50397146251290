/**
 * A Higher Order Component that wraps a component and provided access to the
 * Redux store for accessing and modifying the active modal state.
 *
 * Adds two props to the wrapped component:
 * - activeModalId: The active modal id from the Redux store.
 * - setActiveModal: A memoized function that will set the active modal to the modal
 *     specified by the `targetModalId` prop for the wrapped component.
 *
 * Wrapped components MUST specify the `targetModalId` so they can trigger the
 * correct modal.
 */
import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setActiveModal as setActiveModalActionCreator } from "state/actions";

export default function withModalTrigger(WrappedComponent) {
  return function WrappedComponentWithModalTrigger(props) {
    const dispatch = useDispatch();

    const activeModalId = useSelector((state) => state.modals.activeModalId);

    const setActiveModal = useCallback(
      () => dispatch(setActiveModalActionCreator(props.targetModalId)),
      [dispatch, props]
    );

    return (
      <WrappedComponent
        activeModalId={activeModalId}
        setActiveModal={setActiveModal}
        {...props}
      />
    );
  };
}
