import React from "react";

import { Col, Form } from "react-bootstrap";

import IconField from "../Fields/IconField";
import ImageFileNameField from "../Fields/ImageFileNameField";
import ThemeColourChoiceField from "../Fields/ThemeColourChoiceField";
import UrlPropsField from "../Fields/UrlPropsField";
import {
  getImageStyleProperties,
  getUrlProperties,
  ICON_TYPE,
  IMAGE_TYPE,
} from "../Fields/utils";
import { DEFAULT_CHOICE } from "../types";

export const getModuleProps = (formData) => {
  // Uses the bootstrap theme CSS variables for colours.
  return {
    type: formData.imageType,
    fileName: formData.fileName,
    imgStyle: getImageStyleProperties(formData),
    icon: {
      type: formData.iconType,
      size: formData.iconSize,
      ...(formData.iconColour !== DEFAULT_CHOICE && {
        color: `var(--${formData.iconColour})`,
      }),
    },
    title: {
      text: formData.titleText,
      ...(formData.titleTextColour !== DEFAULT_CHOICE && {
        style: { color: `var(--${formData.titleTextColour})` },
      }),
    },
    ...(formData.subtitleText && {
      subtitle: {
        text: formData.subtitleText,
        ...(formData.subtitleTextColour !== DEFAULT_CHOICE && {
          style: { color: `var(--${formData.subtitleTextColour})` },
        }),
      },
    }),
    ...(formData.textText && {
      text: {
        text: formData.textText,
        ...(formData.textTextColour !== DEFAULT_CHOICE && {
          style: { color: `var(--${formData.textTextColour})` },
        }),
      },
    }),
    url: getUrlProperties(formData),
  };
};

export const CardImageLinkForm = ({ formData, onFieldChange }) => {
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} controlId="imageType">
          <Form.Label>Image type</Form.Label>
          <Form.Control
            as="select"
            required
            custom
            defaultValue=""
            onChange={(event) => onFieldChange("imageType", event.target.value)}
          >
            <option value="" disabled hidden>
              {DEFAULT_CHOICE}
            </option>
            <option value={ICON_TYPE}>Icon</option>
            <option value={IMAGE_TYPE}>Image</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>

      {formData.imageType === ICON_TYPE && (
        <IconField
          helpText="Choose a valid icon from https://icons.getbootstrap.com/, e.g: `AlignMiddle`."
          onFieldChange={onFieldChange}
        />
      )}

      {formData.imageType === IMAGE_TYPE && (
        <ImageFileNameField
          helpText="The file extension is not required. The file must be in the
            `images/links` directory."
          onFieldChange={onFieldChange}
        />
      )}

      <Form.Row>
        <Form.Group as={Col} controlId="titleText">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="My link title"
            required
            onChange={(event) => onFieldChange("titleText", event.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            This field is required.
          </Form.Control.Feedback>
        </Form.Group>

        <ThemeColourChoiceField
          as={Col}
          controlId="titleTextColour"
          className="px-1"
          label="Text colour"
          changeFunction={(value) => onFieldChange("titleTextColour", value)}
        />
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="subtitleText">
          <Form.Label>Subtitle</Form.Label>
          <Form.Control
            type="text"
            placeholder="My link subtitle"
            onChange={(event) =>
              onFieldChange("subtitleText", event.target.value)
            }
          />
        </Form.Group>

        <ThemeColourChoiceField
          as={Col}
          controlId="subtitleTextColour"
          className="px-1"
          label="Subtitle text colour"
          changeFunction={(value) => onFieldChange("subtitleTextColour", value)}
        />
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="textText">
          <Form.Label>Body text</Form.Label>
          <Form.Control
            type="text"
            placeholder="My link text"
            onChange={(event) => onFieldChange("textText", event.target.value)}
          />
        </Form.Group>

        <ThemeColourChoiceField
          as={Col}
          controlId="textTextColour"
          className="px-1"
          label="Body text colour"
          changeFunction={(value) => onFieldChange("textTextColour", value)}
        />
      </Form.Row>

      <UrlPropsField formData={formData} onFieldChange={onFieldChange} />
    </>
  );
};
