/**
 * Gatsby requires wrapping server side rendered pages in a provider to make the
 * redux store available in the context.
 *
 * This also enables us to pre-load content into the store using Graphql queries.
 */
import React from "react";

import { compose, createStore } from "@reduxjs/toolkit";
import { StaticQuery, graphql } from "gatsby";
import { Provider } from "react-redux";

import { setModalTemplates } from "./actions";
import rootReducer from "./reducers";

// Enable the Redux devtools enhancement when in development mode.
const composeEnhancers =
  process.env.NODE_ENV === "development" && typeof window !== "undefined"
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : compose;

// eslint-disable-next-line react/display-name
export default ({ element }) => {
  const store = createStore(rootReducer, composeEnhancers);

  // Pre-load the modal template configurations into the store.
  const dispatch = store.dispatch;
  return (
    <StaticQuery
      query={graphql`
        query ModalTemplateData {
          templates: allFile(
            filter: {
              extension: { eq: "mdx" }
              sourceInstanceName: { eq: "templates" }
              relativeDirectory: { eq: "modals" }
            }
          ) {
            edges {
              node {
                childMdx {
                  frontmatter {
                    # The ModalConfigFragment defines the fields that may be
                    # provided by the MDX frontmatter block.
                    ...ModalConfigFragment
                  }
                  # Body represents the body of the MDX template and is used
                  # as the body of the Modal.
                  body
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        // Extract the config parameters and body from the templates and add to
        // the redux store.
        const modalTemplates = data.templates.edges.reduce(
          (accumulator, { node }) => {
            const { childMdx } = node;

            accumulator[childMdx.frontmatter.id] = {
              config: childMdx.frontmatter.config,
              body: childMdx.body,
            };

            return accumulator;
          },
          {}
        );
        dispatch(setModalTemplates(modalTemplates));

        return <Provider store={store}>{element}</Provider>;
      }}
    />
  );
};
