import React, { useCallback, useState } from "react";

import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { setActiveModal, setActiveRowId, setPageConfig } from "state/actions";

import { COMPONENT_FORM_MAPPING } from "./ComponentForms";

const DynamicComponentForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  const pageName = useSelector((state) => state.dynamicPages.activePageName);
  const pages = useSelector((state) => state.dynamicPages.pages);
  const pageConfig = pages[pageName] || { config: [] };

  const activeRowId = useSelector((state) => state.dynamicPages.activeRowId);
  const activeRowConfig =
    pageConfig.config.find((rowConfig) => rowConfig.name === activeRowId) || {};

  const activeComponentType = useSelector(
    (state) => state.dynamicPages.activeComponentType
  );
  const ComponentForm = COMPONENT_FORM_MAPPING[activeComponentType].form;
  const getModuleProps =
    COMPONENT_FORM_MAPPING[activeComponentType].propFunction;

  const handleFieldChange = useCallback(
    (fieldName, value) => {
      setFormData({ ...formData, [fieldName]: value });
    },
    [formData, setFormData]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      const moduleProps = getModuleProps(formData);

      const newPageConfig = { ...pageConfig };
      const componentConfig = {
        name: uuidv4(),
        columnProps: { xs: 12 },
        moduleComponentName: activeComponentType,
        moduleProps,
      };

      activeRowConfig.children.push(componentConfig);

      // Set the `md` size for all children based on the number of components
      // in the row. We want components in the same row to evenly take up space,
      // otherwise it is recommended to add a new row for full width components.
      activeRowConfig.children.forEach((child) => {
        child.columnProps.md = 12 / activeRowConfig.children.length;
      });

      // Replace the existing row config.
      const foundIndex = pageConfig.config.findIndex(
        (item) => item.name === activeRowId
      );
      newPageConfig.config[foundIndex] = activeRowConfig;

      dispatch(setPageConfig(pageName, newPageConfig));
      dispatch(setActiveModal(null));
      dispatch(setActiveRowId(""));
    }

    setValidated(true);
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit} noValidate>
      <ComponentForm formData={formData} onFieldChange={handleFieldChange} />

      <Button variant="primary" type="submit">
        Add component
      </Button>
    </Form>
  );
};

export default DynamicComponentForm;
