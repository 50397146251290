import React from "react";

import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { deletePage, setActiveModal } from "state/actions";

import { STORAGE_KEY_PAGES } from "./types";

const DynamicPageDeleteForm = () => {
  const dispatch = useDispatch();
  const pages = useSelector((state) => state.dynamicPages.pages);
  const activePageName = useSelector(
    (state) => state.dynamicPages.activePageName
  );

  const handleClick = () => {
    dispatch(deletePage(activePageName));

    const newPages = {
      ...Object.keys(pages).reduce((accumulator, pageName) => {
        if (pageName !== activePageName) {
          accumulator[pageName] = pages[pageName];
        }
        return accumulator;
      }, {}),
    };
    localStorage.setItem(STORAGE_KEY_PAGES, JSON.stringify(newPages));

    dispatch(setActiveModal(null));
  };

  return (
    <>
      <p>Are you sure you want to delete this page?</p>
      <Button variant="danger" block onClick={handleClick}>
        Delete page
      </Button>
    </>
  );
};

export default DynamicPageDeleteForm;
