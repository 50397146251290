/**
 * A Higher Order Component that wraps a component and provides access to the
 * Redux store for changing the active scene in the Virtual Consultation Room.
 *
 * This adds the following prop to the wrapped component:
 *  - triggerSceneChange: A memoized function that will set the active scene id
 *      for the VCR, which will trigger a scene change.
 *
 * Wrapped components MUST specify the `targetSceneId` prop.
 */
import React, { useCallback } from "react";

import { useDispatch } from "react-redux";

import { setActiveSceneId } from "state/actions";

export default function withSceneChangeTrigger(WrappedComponent) {
  return function WrappedComponentWithSceneTrigger(props) {
    const dispatch = useDispatch();

    const triggerSceneChange = useCallback(() => {
      dispatch(setActiveSceneId(props.targetSceneId));
    }, [dispatch, props]);

    return (
      <WrappedComponent triggerSceneChange={triggerSceneChange} {...props} />
    );
  };
}
