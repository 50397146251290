import { DateTime } from "luxon";

export const getCutOffFromEvent = ({ startDate, endDate }) => {
  return DateTime.fromISO(endDate ? endDate : startDate);
};

export const isTimeInRange = (eventDetails) => {
  const now = DateTime.now().setZone("Australia/Sydney");
  const startToday = now.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return startToday <= getCutOffFromEvent(eventDetails.eventDateTimeNaive);
};

const allEqual = (array) => {
  if (!Array.isArray(array) || array.length === 0) {
    return false;
  }

  return array.every((val, index, array) => val === array[0]);
};

export const formatEventDateTime = (
  eventDateTimesNaive,
  datetimeFormat,
  rangeSeparator = " to "
) => {
  if (allEqual(eventDateTimesNaive)) {
    // If start/end times are the same. Take first one.
    // Don't display the same time twice.
    eventDateTimesNaive = [eventDateTimesNaive[0]];
  }
  return eventDateTimesNaive
    .filter((value) => value !== undefined)
    .map((dateTime) => DateTime.fromISO(dateTime).toFormat(datetimeFormat))
    .join(rangeSeparator);
};
