import { Col, Modal, Row } from "react-bootstrap";
import { ShareFill } from "react-bootstrap-icons";
import ButtonLink from "components/Links/ButtonLink";
import * as React from 'react';
export default {
  Col,
  Modal,
  Row,
  ShareFill,
  ButtonLink,
  React
};