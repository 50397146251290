import React, { useCallback, useState } from "react";

import _throttle from "lodash/throttle";
import PropTypes from "prop-types";
import { Globe, Map } from "react-bootstrap-icons";

const TiltButton = ({ map }) => {
  const initialPitch = map.getPitch();
  const [is2D, setis2D] = useState(initialPitch === 0);

  const updatePitch = useCallback(
    _throttle(() => {
      const pitch = map.getPitch();
      setis2D(pitch === 0);
    }, 400),
    [map]
  );

  map.on("pitch", updatePitch);

  const handleClick = () => {
    if (is2D) {
      map.setPitch(60);
    } else {
      map.setPitch(0);
    }
  };

  return (
    <button
      type="button"
      className="font-weight-bold"
      aria-label="Toggle between 2D and 3D view"
      onClick={handleClick}
    >
      {is2D ? <Globe size={18} /> : <Map size={18} />}
    </button>
  );
};

TiltButton.propTypes = {
  map: PropTypes.object.isRequired,
};

export default TiltButton;
