import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

const PDFViewer = (props) => {
  const pdfs = useStaticQuery(graphql`
    query PDFs {
      pdfs: allFile(
        filter: { extension: { eq: "pdf" }, sourceInstanceName: { eq: "docs" } }
      ) {
        edges {
          node {
            id
            publicURL
            name
            extension
          }
        }
      }
    }
  `);
  const selectedPDF = pdfs.pdfs.edges.find(({ node }) => {
    return node.name === props.pdfName;
  });
  if (selectedPDF) {
    return (
      <embed
        src={selectedPDF.node.publicURL}
        type="application/pdf"
        height={props.height}
        width={props.width}
      />
    );
  }
  return null;
};

PDFViewer.propTypes = {
  pdfName: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

PDFViewer.defaultProps = {
  height: 850,
  width: 600,
};

export default PDFViewer;
