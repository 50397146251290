import React from "react";

import Nav from "react-bootstrap/Nav";

import { generalConfig } from "src/app-config";

const GoogleTranslate = () => {
  return (
    <>
      {generalConfig.googleTranslate.languages.map((language, index) => (
        <Nav.Item
          className="navbar-text notranslate text-primary"
          key={`lang-${index}`}
        >
          {language.text}
        </Nav.Item>
      ))}
      <div id="google-translate-element" />
    </>
  );
};

export default GoogleTranslate;
