import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Thumbnail from "components/Images/Thumbnail";

import MapboxInset from "./Mapbox/MapboxInset";

const popupModules = {
  Thumbnail,
};

const MapInset = (props) => {
  const {
    mapSettings,
    layers,
    sites,
    geocoderConfig,
    layerControls,
    popups,
    icons,
    mapContainerStyles,
    commonLegend,
    layerLegends,
  } = props;

  const popupsWithModule = popups.map((popup) => {
    const featureProps = popup.featureProps.map((feature) => {
      const popupModule = popupModules[feature.moduleComponentName];
      return { ...feature, module: popupModule };
    });
    return { ...popup, featureProps };
  });

  return (
    <Row noGutters className="flex-wrap-reverse">
      <Col style={mapContainerStyles}>
        <MapboxInset
          {...mapSettings}
          layers={layers}
          popups={popupsWithModule}
          icons={icons}
          layerControls={layerControls}
          sites={sites}
          geocoderConfig={geocoderConfig}
          commonLegend={commonLegend}
          layerLegends={layerLegends}
        />
      </Col>
    </Row>
  );
};

MapInset.propTypes = {
  geocoderConfig: PropTypes.object.isRequired,
  layers: PropTypes.array.isRequired,
  layerControls: PropTypes.array.isRequired,
  mapSettings: PropTypes.object.isRequired,
  icons: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  popups: PropTypes.array.isRequired,
  mapContainerStyles: PropTypes.object.isRequired,
  commonLegend: PropTypes.array.isRequired,
  layerLegends: PropTypes.object.isRequired,
};

export default MapInset;
