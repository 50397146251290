/**
 * A Higher Order Component that wraps a component with mapbox actions.
 * Context provider allows access to map instance being used.
 */

import React, { useContext } from "react";

import _has from "lodash/has";
import _isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";

import { setLayerVisibility, setTopicLayers } from "state/actions";

import { MapContext } from "../MapContext";

const getBasemapLayerIds = () => {
  const alternateLayers = JSON.parse(
    sessionStorage.getItem("alternate-basemap")
  );
  const primaryLayers = JSON.parse(sessionStorage.getItem("primary-basemap"));
  return alternateLayers.concat(primaryLayers);
};

// Set visible layers with the exception of basemap layers.
export const setInitialVisibleLayers = (map, visibleLayerIds) => {
  const { layers } = map.getStyle();
  const basemapLayerIds = getBasemapLayerIds();
  layers.forEach((layer) => {
    if (
      !_has(layer, "metadata.mapbox:featureComponent") &&
      !basemapLayerIds.includes(layer.id)
    ) {
      const isVisible = visibleLayerIds.includes(layer.id);
      const visibility = isVisible ? "visible" : "none";
      map.setLayoutProperty(layer.id, "visibility", visibility);
    }
  });
};

export default function withMapboxActions(WrappedComponent) {
  return function WrappedwithMapboxActions(props) {
    const dispatch = useDispatch();
    const [mapContext] = useContext(MapContext);
    const topicLayerIds = useSelector(
      (state) => state.interactiveMap.topicLayerIds
    );

    /**
     * Zoom to a location on the map.
     * @param locationOptions an object with mapbox camera options as specified by the
     * mapbox-gl-js API, all properties are optional
     * https://docs.mapbox.com/mapbox-gl-js/api/properties/#cameraoptions
     */
    const zoomToLocation = (locationOptions) => {
      mapContext.map.flyTo(locationOptions);
    };

    /**
     * Set map layers visibility.
     * @param layerIds array of unique identifiers to select layers from the map instance.
     * @param isVisible boolean to set visibility of layers.
     */
    const setLayersVisibility = (layerIds, isVisible) => {
      const visibilty = isVisible ? "visible" : "none";
      layerIds.forEach((layerId) => {
        mapContext.map.setLayoutProperty(layerId, "visibility", visibilty);
        dispatch(setLayerVisibility(layerId, isVisible));
      });
    };

    /**
     * Mapbox doesn't really have "basemaps", the style encapsulates all layers and contextual data (basemap).
     * We require a basemap toggle so are adding a raster layer that can be toggled on or off. When this alternate
     * raster is toggled on we turn off all mapbox base layers in our default style.
     * @param activate the basemap to turn on primary/alternate.
     */
    const switchBasemap = (activate) => {
      ["alternate", "primary"].forEach((key) => {
        const layerIds = JSON.parse(sessionStorage.getItem(`${key}-basemap`));
        setLayersVisibility(layerIds, activate === key);
      });
    };

    /**
     * Set map layers visibility and map view.
     * @param locationOptions object with locationOptions to zoom to.
     * @param layerOptions array with layers { id:"", visible: bool }.
     */
    const setTopicLayersAndLocation = (locationOptions, layerOptions) => {
      const visibleLayerIds = layerOptions
        .filter((layer) => layer.visible)
        .map((visibleLayer) => visibleLayer.id);

      if (visibleLayerIds.length !== 0) {
        setLayersVisibility(visibleLayerIds, true);
      }
      if (!_isEmpty(locationOptions)) {
        zoomToLocation(locationOptions);
      }
    };

    /**
     * Switch the map to a new topic. Turn currently active topic layers off (if any) and then turn on new
     * topic layers.
     * @param locationOptions object with locationOptions to zoom to.
     * @param layerOptions array with layers { id:"", visible: bool }.
     */
    const setMapTopic = (locationOptions, layerOptions) => {
      const newTopicLayerIds = layerOptions.map((topicLayer) => topicLayer.id);
      setLayersVisibility(topicLayerIds, false);
      dispatch(setTopicLayers(newTopicLayerIds));
      setTopicLayersAndLocation(locationOptions, layerOptions);
    };

    return (
      <WrappedComponent
        zoomToLocation={zoomToLocation}
        setLayersVisibility={setLayersVisibility}
        switchBasemap={switchBasemap}
        setMapTopic={setMapTopic}
        {...props}
      />
    );
  };
}
