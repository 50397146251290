import React, { useState } from "react";

import { Col, Row } from "react-bootstrap";
import { EmojiFrown, EmojiNeutral, EmojiSmile } from "react-bootstrap-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const FeedbackCarousel = ({ questions, completedMessage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [answers, setAnswers] = useState({});

  const handleAnswer = (id, choice) => {
    setAnswers({ ...answers, [id]: choice });
    setActiveIndex(activeIndex + 1);
  };

  return (
    <Carousel
      selectedItem={activeIndex}
      onChange={(index) => setActiveIndex(index)}
      showThumbs={false}
    >
      {questions.map(({ id, text }) => {
        const selectedAnswer = answers[id];

        return (
          <Row
            key={`slide-${id}`}
            className="p-5 align-content-center align-items-center justify-content-center"
          >
            <Col xs={4} className="text-center">
              <EmojiFrown
                size={100}
                onClick={() => handleAnswer(id, "unhappy")}
                style={{ cursor: "pointer" }}
                className={
                  selectedAnswer === "unhappy" ? "selected-answer" : ""
                }
              />
            </Col>
            <Col xs={4} className="text-center">
              <EmojiNeutral
                size={100}
                onClick={() => handleAnswer(id, "neutral")}
                style={{ cursor: "pointer" }}
                className={
                  selectedAnswer === "neutral" ? "selected-answer" : ""
                }
              />
            </Col>
            <Col xs={4} className="text-center">
              <EmojiSmile
                size={100}
                onClick={() => handleAnswer(id, "happy")}
                style={{ cursor: "pointer" }}
                className={selectedAnswer === "happy" ? "selected-answer" : ""}
              />
            </Col>

            <Col xs={12} className="text-center mt-5">
              {text}
            </Col>
          </Row>
        );
      })}

      <Row className="p-5 align-content-center align-items-center justify-content-center">
        <Col xs={12} className="text-center">
          {completedMessage}
        </Col>
      </Row>
    </Carousel>
  );
};

export default FeedbackCarousel;
