import {
  DELETE_PAGE,
  DELETE_ROW,
  SET_ACTIVE_COMPONENT_TYPE,
  SET_ACTIVE_PAGE_NAME,
  SET_ACTIVE_ROW_ID,
  SET_PAGE_CONFIG,
  SET_PAGES_CONFIG,
} from "../constants/ActionTypes";

const initialState = {
  activeComponentType: "",
  activePageName: "",
  activeRowId: "",
  pages: {},
};

const dynamicPages = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_PAGE:
      return {
        ...state,
        pages: {
          ...Object.keys(state.pages).reduce((accumulator, pageName) => {
            if (pageName !== action.pageName) {
              accumulator[pageName] = state.pages[pageName];
            }
            return accumulator;
          }, {}),
        },
      };
    case DELETE_ROW:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.pageName]: {
            ...state.pages[action.pageName],
            config: state.pages[action.pageName].config.filter(
              (rowConfig) => rowConfig.name !== action.rowId
            ),
          },
        },
      };
    case SET_ACTIVE_COMPONENT_TYPE:
      return {
        ...state,
        activeComponentType: action.activeComponentType,
      };
    case SET_ACTIVE_PAGE_NAME:
      return {
        ...state,
        activePageName: action.activePageName,
      };
    case SET_ACTIVE_ROW_ID:
      return {
        ...state,
        activeRowId: action.activeRowId,
      };
    case SET_PAGE_CONFIG:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.pageName]: action.pageConfig,
        },
      };
    case SET_PAGES_CONFIG:
      return {
        ...state,
        pages: action.pages,
      };
    default:
      return state;
  }
};

export default dynamicPages;
