import React from "react";

import { Col, Form } from "react-bootstrap";

import ImageFileNameField from "../Fields/ImageFileNameField";
import ThemeColourChoiceField from "../Fields/ThemeColourChoiceField";
import UrlPropsField from "../Fields/UrlPropsField";
import { getImageStyleProperties, getUrlProperties } from "../Fields/utils";
import { DEFAULT_CHOICE } from "../types";

export const getModuleProps = (formData) => {
  return {
    alignCenter: formData.alignCenter,
    belowLinkText: formData.belowLinkText,
    fileName: formData.fileName,
    imgStyle: getImageStyleProperties(formData),
    url: getUrlProperties(formData),
    ...(formData.textColour !== DEFAULT_CHOICE && {
      // Use the bootstrap theme CSS variable.
      textStyle: { color: `var(--${formData.textColour})` },
    }),
  };
};

export const ImageLinkForm = ({ formData, onFieldChange }) => {
  return (
    <>
      <ImageFileNameField
        helpText="The file extension is not required. The file must be in the
          `images/links` directory."
        onFieldChange={onFieldChange}
      />

      <Form.Group controlId="alignCenter">
        <Form.Check
          type="checkbox"
          label="Align center?"
          onChange={(event) =>
            onFieldChange("alignCenter", event.target.checked)
          }
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} controlId="belowLinkText">
          <Form.Label>Below link text</Form.Label>
          <Form.Control
            type="text"
            placeholder="Text shown below the image link"
            onChange={(event) =>
              onFieldChange("belowLinkText", event.target.value)
            }
          />
        </Form.Group>

        <ThemeColourChoiceField
          as={Col}
          controlId="textColour"
          className="px-1"
          label="Text colour"
          changeFunction={(value) => onFieldChange("textColour", value)}
        />
      </Form.Row>

      <UrlPropsField formData={formData} onFieldChange={onFieldChange} />
    </>
  );
};
