import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import useToggle from "hooks/useToggle";

import { basemaps } from "./types";

const BasemapSelector = (props) => {
  const { basemaps, switchBasemap } = props;
  const [isPrimaryBasemap, toggleIsPrimaryBasemap] = useToggle(true);

  const inactiveBasemapKey = isPrimaryBasemap ? "alternate" : "primary";
  const inactiveBasemap = basemaps[inactiveBasemapKey];
  const { imageName } = inactiveBasemap;

  const handleClick = () => {
    toggleIsPrimaryBasemap();
    switchBasemap(inactiveBasemapKey);
  };

  const data = useStaticQuery(graphql`
    query BasemapThumbnails {
      images: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "basemaps" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 100, maxHeight: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const image = data.images.edges.find(({ node }) => {
    return node.name === imageName;
  });

  return (
    <Button
      variant="light"
      className="basemap-selector p-0"
      onClick={handleClick}
    >
      <>
        {image ? (
          <Card.Img
            as={Img}
            fluid={image.node.childImageSharp.fluid}
            alt={image.node.name}
          />
        ) : (
          <>{imageName}</>
        )}
      </>
    </Button>
  );
};

BasemapSelector.propTypes = {
  basemaps: basemaps,
  switchBasemap: PropTypes.func.isRequired,
};

export default BasemapSelector;
