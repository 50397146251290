/**
 * A higher order component to inject the link images query into the wrapped
 * link component.
 */

import React from "react";

import { StaticQuery, graphql } from "gatsby";

// Require one of the `path` or `targetModalId` properties.
const requiredLinkPropsCheck = (props, propName, componentName) => {
  if (!props.path && !props.targetModalId) {
    return new Error(
      `One of "path" or "targetModalId" is required by the ${componentName} component.`
    );
  }

  if (props.path && !props.isExternal && !props.path.startsWith("/")) {
    return new Error(`Internal paths must start with a "/".`);
  }
};

const withImageData = (WrappedComponent) => {
  return function WrappedWithImageData(props) {
    return (
      <StaticQuery
        query={graphql`
          query LinkImages {
            images: allFile(
              filter: {
                extension: { regex: "/(jpg)|(jpeg)|(png)/" }
                relativeDirectory: { eq: "links" }
                sourceInstanceName: { eq: "images" }
              }
            ) {
              edges {
                node {
                  id
                  name
                  childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => <WrappedComponent {...props} imageData={data} />}
      />
    );
  };
};

export { requiredLinkPropsCheck, withImageData };
