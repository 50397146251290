import {
  INCREMENT_LIGHTBOX_KEY,
  SET_LIGHTBOX_SOURCE_INDEX,
  SET_LIGHTBOX_SOURCES,
  TOGGLE_LIGHTBOX_STATE,
} from "../constants/ActionTypes";

const initialState = {
  lightboxKey: 0,
  sourceIndex: 0,
  sources: [],
  toggled: false,
};

const lightbox = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_LIGHTBOX_KEY:
      // FSLightbox doesn't react to changes to the lightbox sources. The
      // lightbox must be re-mounted, which can be achieved by changing the `key`.
      return {
        ...state,
        lightboxKey: state.lightboxKey + 1,
      };
    case SET_LIGHTBOX_SOURCE_INDEX:
      return {
        ...state,
        sourceIndex: action.sourceIndex,
      };
    case SET_LIGHTBOX_SOURCES:
      return {
        ...state,
        sources: action.sources,
      };
    case TOGGLE_LIGHTBOX_STATE:
      return {
        ...state,
        toggled: !state.toggled,
      };
    default:
      return state;
  }
};

export default lightbox;
