import React, { Component } from "react";

import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { Container, Nav, Navbar } from "react-bootstrap";
import {
  BookmarkFill,
  BookmarksFill,
  HouseFill,
  ShareFill,
  VectorPen,
} from "react-bootstrap-icons";
import { connect } from "react-redux";

import Link from "components/Links/Link";
import { INDEX_PATH, generalConfig, navConfig } from "src/app-config";

import NavLogo from "./NavLogo";
import NavTitle from "./NavTitle";

// Add icons here to make them available for use in the navbar.
const icons = {
  BookmarkFill,
  BookmarksFill,
  HouseFill,
  ShareFill,
  VectorPen,
};

const navItemFromConf = (navItemConfig, key, activeSectionId) => {
  if (navItemConfig.item === "logo") {
    return (
      <NavLogo
        key={key}
        filename={navItemConfig.filename}
        imgStyle={navItemConfig.style}
        linkHref={navItemConfig.linkHref}
      />
    );
  } else if (navItemConfig.item === "title") {
    return <NavTitle key={key} title={navItemConfig.text} />;
  } else if (navItemConfig.item === "modal-link") {
    return (
      <Nav.Item key={key} className="nav-spaced nav-modal-link">
        <Link targetModalId={navItemConfig.modalId}>{navItemConfig.text}</Link>
      </Nav.Item>
    );
  } else if (navItemConfig.item === "icon") {
    const NavIcon = icons[navItemConfig.icon];
    return (
      <Nav.Item key={key} className="nav-spaced nav-icon">
        <Link path={navItemConfig.link} targetModalId={navItemConfig.modalId}>
          <NavIcon />
        </Link>
      </Nav.Item>
    );
  } else if (navItemConfig.item === "page-link-noise") {
    const onclick = () => {
      /**
       * Pass through the language the user has selected in Google Translate
       * onto the page to the Noise Tool.
       *
       * Not all languages/codes that are provided on this OCP site work on the
       * Noise Tool page. All unsupported languages use default English.
       */
      const selectedLanguage = window.document.querySelector(
        "#google-translate-element a span"
      );
      const languageCode =
        !!selectedLanguage &&
        generalConfig.googleTranslate.noiseTool.find(
          (language) => language.text === selectedLanguage.outerText
        );
      const code = languageCode ? languageCode.code : "en";

      window.open(
        `${navItemConfig.link}?lng=${code}`,
        "_blank",
        "noopener,noreferrer"
      );
    };

    return (
      <Nav.Item key={key} className="nav-spaced nav-page-link">
        <button onClick={onclick} role="link" className="nav-button-link">
          {navItemConfig.text}
        </button>
      </Nav.Item>
    );
  }

  // The default page links may not be separate pages and should be marked as active
  // if the active section id matches the anchor path. If it is not an external
  // URL then the Gatsby-Link component handles the active state via the URL path.
  const isActiveSectionLink = `#${activeSectionId}` === navItemConfig.link;

  // This is a normal page link
  return (
    <Nav.Item key={key} className="nav-spaced nav-page-link">
      <Link
        path={navItemConfig.link}
        isExternal={navItemConfig.isExternal}
        target={navItemConfig.target}
        className={isActiveSectionLink ? "active" : ""}
      >
        {navItemConfig.text}
      </Link>
    </Nav.Item>
  );
};

const getPageConfig = (props) => {
  let pageNavConfig = navConfig[props.page];
  if (_isEmpty(pageNavConfig)) {
    pageNavConfig = navConfig[INDEX_PATH];
  }

  return pageNavConfig;
};

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navHeight: 0,
      navExpanded: false,
      pageNavConfig: getPageConfig(props),
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  closeNav = () => {
    this.setState({ navExpanded: false });
  };

  handleScroll = () => {
    const documentHeight = document.documentElement.clientHeight;
    const navbar = document.getElementById("main-nav-bar");
    if (
      document.body.scrollTop > documentHeight ||
      document.documentElement.scrollTop > documentHeight
    ) {
      navbar.style.removeProperty("top");
    } else {
      navbar.style.top = `-${this.state.navHeight}px`;
    }
  };

  componentDidMount() {
    const navHeight = document.getElementById("nav-bar-container").clientHeight;
    this.setState({ navHeight });
  }

  componentDidUpdate(prevProps) {
    if (getPageConfig(this.props) !== getPageConfig(prevProps)) {
      this.setState({ pageNavConfig: getPageConfig(this.props) });
    }

    if (this.state.pageNavConfig.hideUntilScroll) {
      window.addEventListener("scroll", this.handleScroll);
      // Remove any tab-padding as the navbar scrolls into view over the top of content.
      document.documentElement.style.setProperty("--tab-padding", "0px");
    } else {
      // Remove any negative top setting added via a hidden navbar.
      document.getElementById("main-nav-bar").style.removeProperty("top");
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getNonCollapsing() {
    return this.state.pageNavConfig.items.nonCollapsing.map(
      (navItemConfig, index) =>
        navItemFromConf(
          navItemConfig,
          `prebar-${index}`,
          this.props.activeSectionId
        )
    );
  }

  render() {
    return (
      <div id="nav-bar-container" className="main-nav bg-primary">
        <Container className="lg-only-nav">
          {this.getNonCollapsing()}
          <NavTitle title={this.state.pageNavConfig.title} />
        </Container>
        <Navbar
          id="main-nav-bar"
          expand="lg"
          bg="primary"
          className="justify-content-lg-center container"
          variant="dark"
          onToggle={this.setNavExpanded}
          expanded={this.state.navExpanded}
          style={{
            ...(this.state.pageNavConfig.hideUntilScroll && {
              top: `-${this.state.navHeight}px`,
            }),
          }}
        >
          <div className="md-only-nav">
            <NavTitle title={this.state.pageNavConfig.title} />
            <Container className="md-only-nav">
              {this.getNonCollapsing()}
              <Navbar.Toggle aria-controls="navbar-nav" />
            </Container>
          </div>
          <div className="flex-grow-1"></div>
          <Navbar.Collapse id="navbar-nav">
            <Nav className="navbar-links" onClick={this.closeNav}>
              {this.state.pageNavConfig.items.collapsing.map(
                (navItemConfig, index) =>
                  navItemFromConf(
                    navItemConfig,
                    `postbar-${index}`,
                    this.props.activeSectionId
                  )
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

NavBar.propTypes = {
  page: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  activeSectionId: state.pageSections.activeSectionId,
});

export default connect(mapStateToProps)(NavBar);
